import { ClienteEditCreationDTO, CustomerResponseDTO, FindAllCustomersFilters, GetAddOnByIdResponseDTO, GetAddOnsResponseDTO, GetAllRappLegaleResponseDTO, GetRapplegaleByIdDTO, PostUpdateAddOnRequestDTO, RappLegaleEditCreationDTO, contactsEditCreateRequestDTO, corporateCenterDTO, } from "./dto";
import { ClientiServiceImpl } from "./serviceImpl";

export interface ClientiService {
    GetCustomers(filters: FindAllCustomersFilters): Promise<any>
    GetCustomersById(id: string): Promise<any>
    GetCustomersByCode(code: string): Promise<any>
    GetCustomerByCompanyName(name: string): Promise<any>
    GetRappLegales(): Promise<GetAllRappLegaleResponseDTO>
    GetRappLegaleById(id: string): Promise<any>
    UpdateRappLegaleById(id: string, data: RappLegaleEditCreationDTO): Promise<any>
    DeleteRappLegaleById(id: string): Promise<any>
    DeactivateRappLegaleById(id: string): Promise<any>
    GetContactsById(id: string): Promise<any>
    GetCorporateCenterById(id: string): Promise<any>
    EditCustomerRequest(id: string, data: ClienteEditCreationDTO): Promise<void>
    EditLegalRapRequest(id: string, data: RappLegaleEditCreationDTO): Promise<void>
    EditCustomerContacts(id: string, data: contactsEditCreateRequestDTO): Promise<void>
    CustomerCreation(data: ClienteEditCreationDTO): Promise<number>
    createRappLegale(data: RappLegaleEditCreationDTO): Promise<void>
    createContacts(data: contactsEditCreateRequestDTO): Promise<void>
    createCompanyCenter(data: corporateCenterDTO): Promise<void>
    getAddOnById(id: string): Promise<GetAddOnByIdResponseDTO>
    getAddOns(): Promise<GetAddOnsResponseDTO>
    postAddOn(data: PostUpdateAddOnRequestDTO): Promise<string>
    updateAddOn(id: string, data: PostUpdateAddOnRequestDTO): Promise<void>
    deleteAddOn(id: string): Promise<void>
}

export function NewClientiService(): ClientiService {
    return new ClientiServiceImpl();
}
