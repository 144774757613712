import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { GetAllOtherInfoResponseDTO, OtherInfoDTO, OtherInfoResponseDTO } from "./dataService/dto";
import { NewOtherInfoService } from "./dataService/service";

interface OtherInfoState {
    otherInfoCreationRequest: OtherInfoDTO
    OthInfoUpdateRequest: OtherInfoDTO
    otherDataCreationStatus: PromiseStatuses
    getAllOtherInfoStatus: PromiseStatuses
    OthInfoUpdateStatus: PromiseStatuses
    createOtherInfoStatus: PromiseStatuses
    getByIdOtherInfoStatus: PromiseStatuses
    DeleteIdOtherInfoStatus: PromiseStatuses
    OtherInfoValidationStatus: PromiseStatuses
    getAllOtherInfoResponse: GetAllOtherInfoResponseDTO
    GetByIdOtherInfoResponse: OtherInfoDTO
    otherInfoInstances: OtherInfoResponseDTO[]
    errorLabel?: string
}

const initialState: OtherInfoState = {
    otherInfoInstances: [],
    otherDataCreationStatus: 'idle',
    getAllOtherInfoStatus: 'idle',
    getByIdOtherInfoStatus: 'idle',
    createOtherInfoStatus: 'idle',
    DeleteIdOtherInfoStatus: 'idle',
    OthInfoUpdateStatus: 'idle',
    OtherInfoValidationStatus: 'idle',
    otherInfoCreationRequest: {},
    OthInfoUpdateRequest: {},
    GetByIdOtherInfoResponse: {},
    getAllOtherInfoResponse: {
        data: [],
        total: 0,
        page: 0,
        perPage: 0,
        next: '',
        prev: '',
        totalPage: 0
    }
}

export const GetAllOtherInfo = createAsyncThunk(
    'PM/OtherInfo/GetAll',
    async (request, thunkApi): Promise<GetAllOtherInfoResponseDTO> => {
        const otherInfoService = NewOtherInfoService()

        return otherInfoService.GetAllOtherInfo().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetByIdOtherInfo = createAsyncThunk(
    'PM/OtherInfo/GetOtherInfoById',
    async (id: string, thunkApi): Promise<OtherInfoResponseDTO> => {
        const otherInfoService = NewOtherInfoService()

        return otherInfoService.GetOtherInfoById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)


export const OtherInfoUpdate = createAsyncThunk(
    'PM/OtherInfo/Update',
    async (request: {data: OtherInfoDTO, id: string}, thunkApi): Promise<void> => {
        const otherInfoService = NewOtherInfoService()

        return otherInfoService.OtherInfoUpdate(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const OtherInfoCreation = createAsyncThunk(
    'PM/OtherData/createOtherInfo',
    async (request: OtherInfoDTO, thunkApi): Promise<void> => {
        const otherInfoService = NewOtherInfoService()

        return otherInfoService.CreateOtherInfo(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DeleteOtherInfo = createAsyncThunk(
    'PM/OtherData/DeleteOtherInfo',
    async (id: string, thunkApi): Promise<void> => {
        const otherInfoService = NewOtherInfoService()

        return otherInfoService.DeleteOtherInfo(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OtherInfoValidation = createAsyncThunk(
    'PM/OtherInfo/Validate',
    async (request: OtherInfoDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetErrorLabel())

        if(request.drivingLicense === undefined &&
            request.nativeLanguage === undefined &&
            request.oralExpressionSkill === undefined &&
            request.organizationalSkill === undefined &&
            request.otherInfo === undefined &&
            request.otherLanguage === undefined &&
            request.otherSkill === undefined &&
            request.personalSkill === undefined &&
            request.readSkill === undefined &&
            request.relationalSkill === undefined &&
            request.technicalSkill === undefined &&
            request.writeSkill === undefined
        ) {
            thunkApi.dispatch(setErrorLabel('Attenzione: tutti i campi sono vuoti!'))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const PMOtherInfo = createSlice({
    name: 'PM/otherInfoSlice',
    initialState,
    reducers: {
        setDrivingLicense: (state, action) => {
            state.otherInfoCreationRequest.drivingLicense = action.payload
        },
        setNativeLanguage: (state, action) => {
            state.otherInfoCreationRequest.nativeLanguage = action.payload
        },
        setOtherLanguage: (state, action) => {
            state.otherInfoCreationRequest.otherLanguage = action.payload
        },
        setReadSkill: (state, action) => {
            state.otherInfoCreationRequest.readSkill = action.payload
        },
        setWriteSkill: (state, action) => {
            state.otherInfoCreationRequest.writeSkill = action.payload
        },
        setOralExpressionSkill: (state, action) => {
            state.otherInfoCreationRequest.oralExpressionSkill = action.payload
        },
        setPersonalSkill: (state, action) => {
            state.otherInfoCreationRequest.personalSkill = action.payload
        },
        setRelationalSkill: (state, action) => {
            state.otherInfoCreationRequest.relationalSkill = action.payload
        },
        setOrganizationalSkill: (state, action) => {
            state.otherInfoCreationRequest.organizationalSkill = action.payload
        },
        setTechnicalSkill: (state, action) => {
            state.otherInfoCreationRequest.technicalSkill = action.payload
        },
        setOtherSkill: (state, action) => {
            state.otherInfoCreationRequest.otherSkill = action.payload
        },
        //UPDATE 
        setUpdateDrivingLicense: (state, action) => {
            state.OthInfoUpdateRequest.drivingLicense = action.payload
        },
        setUpdateNativeLanguage: (state, action) => {
            state.OthInfoUpdateRequest.nativeLanguage = action.payload
        },
        setUpdateOtherLanguage: (state, action) => {
            state.OthInfoUpdateRequest.otherLanguage = action.payload
        },
        setUpdateReadSkill: (state, action) => {
            state.OthInfoUpdateRequest.readSkill = action.payload
        },
        setUpdateWriteSkill: (state, action) => {
            state.OthInfoUpdateRequest.writeSkill = action.payload
        },
        setUpdateOralExpressionSkill: (state, action) => {
            state.OthInfoUpdateRequest.oralExpressionSkill = action.payload
        },
        setUpdatePersonalSkill: (state, action) => {
            state.OthInfoUpdateRequest.personalSkill = action.payload
        },
        setUpdateRelationalSkill: (state, action) => {
            state.OthInfoUpdateRequest.relationalSkill = action.payload
        },
        setUpdateOrganizationalSkill: (state, action) => {
            state.OthInfoUpdateRequest.organizationalSkill = action.payload
        },
        setUpdateTechnicalSkill: (state, action) => {
            state.OthInfoUpdateRequest.technicalSkill = action.payload
        },
        setUpdateOtherSkill: (state, action) => {
            state.OthInfoUpdateRequest.otherSkill = action.payload
        },

        // ERROR
        setErrorLabel: (state, action) => {
            state.errorLabel = action.payload
        },
        resetErrorLabel: (state) => {
            state.errorLabel = undefined
        },

        setPMId: (state, action) => {
            state.otherInfoCreationRequest.personalManagementId = action.payload.toString()
        },
        addOthInfoInstance: (state, action) => {
            state.otherInfoInstances.push(action.payload)
        },
        resetOthInfoInstance: (state) => {
            state.otherInfoInstances = []
            console.log('reset ', state.otherInfoInstances)
        },
        setUpdateRequest: (state, action) => {
            state.OthInfoUpdateRequest = action.payload
        }, 
        resetOtherInfoCreateForm: (state) => {
            state.otherInfoCreationRequest = {
                personalSkill: undefined,
                relationalSkill: undefined,
                organizationalSkill: undefined,
                technicalSkill: undefined,
                otherSkill: undefined,
                nativeLanguage: undefined,
                otherLanguage: undefined,
                readSkill: undefined,
                writeSkill: undefined,
                oralExpressionSkill: undefined,
                drivingLicense: undefined,
                personalManagementId: state.otherInfoCreationRequest.personalManagementId
            }
        },
        resetOtherInfoUpdateForm: (state) => {
            state.OthInfoUpdateRequest = {
                personalSkill: undefined,
                relationalSkill: undefined,
                organizationalSkill: undefined,
                technicalSkill: undefined,
                otherSkill: undefined,
                nativeLanguage: undefined,
                otherLanguage: undefined,
                readSkill: undefined,
                writeSkill: undefined,
                oralExpressionSkill: undefined,
                drivingLicense: undefined,
                personalManagementId: state.otherInfoCreationRequest.personalManagementId
            }
        },
        resetOtherInfoCreateStatus: (state, action) => {
            state.createOtherInfoStatus = action.payload
        },
        resetGetByidStatus: (state, action) => {
            state.getByIdOtherInfoStatus = action.payload
        },
        resetDeleteStatus: (state, action) => {
            state.DeleteIdOtherInfoStatus = action.payload
        },

        resetUpdateStatus: (state, action) => {
            state.OthInfoUpdateStatus = action.payload
        },
        resetOtherInfoValidationStatus: (state) => {
            state.OtherInfoValidationStatus = 'idle'
        }
        
    },
    extraReducers(builder) {
        builder
        .addCase(GetAllOtherInfo.pending, (state) => {
            state.getAllOtherInfoStatus = 'loading'
        })
        .addCase(GetAllOtherInfo.fulfilled, (state, action) => {
            state.getAllOtherInfoStatus = 'successfully'
            state.getAllOtherInfoResponse = action.payload
        })
        .addCase(GetAllOtherInfo.rejected, (state) => {
            state.getAllOtherInfoStatus = 'failed'
        })

        .addCase(GetByIdOtherInfo.pending, (state) => {
            state.getByIdOtherInfoStatus = 'loading'
        })
        .addCase(GetByIdOtherInfo.fulfilled, (state, action) => {
            state.getByIdOtherInfoStatus = 'successfully'
            state.GetByIdOtherInfoResponse = action.payload
        })
        .addCase(GetByIdOtherInfo.rejected, (state) => {
            state.getByIdOtherInfoStatus = 'failed'
        })

        .addCase(OtherInfoCreation.pending, (state) => {
            state.createOtherInfoStatus = 'loading'
        })
        .addCase(OtherInfoCreation.fulfilled, (state, action) => {
            state.createOtherInfoStatus = 'successfully'
        })
        .addCase(OtherInfoCreation.rejected, (state) => {
            state.createOtherInfoStatus = 'failed'
        })

        .addCase(DeleteOtherInfo.pending, (state) => {
            state.DeleteIdOtherInfoStatus = 'loading'
        })
        .addCase(DeleteOtherInfo.fulfilled, (state, action) => {
            state.DeleteIdOtherInfoStatus = 'successfully'
        })
        .addCase(DeleteOtherInfo.rejected, (state) => {
            state.DeleteIdOtherInfoStatus = 'failed'
        })

        .addCase(OtherInfoUpdate.pending, (state) => {
            state.OthInfoUpdateStatus = 'loading'
        })
        .addCase(OtherInfoUpdate.fulfilled, (state) => {
            state.OthInfoUpdateStatus = 'successfully'
        })
        .addCase(OtherInfoUpdate.rejected, (state) => {
            state.OthInfoUpdateStatus = 'failed'
        })

        .addCase(OtherInfoValidation.pending, (state) => {
            state.OtherInfoValidationStatus = 'loading'
        })
        .addCase(OtherInfoValidation.fulfilled, (state) => {
            state.OtherInfoValidationStatus = 'successfully'
        })
        .addCase(OtherInfoValidation.rejected, (state) => {
            state.OtherInfoValidationStatus = 'failed'
        })
    },
})

export const {
  setDrivingLicense,
  setNativeLanguage,
  setOralExpressionSkill,
  setOrganizationalSkill,
  setOtherLanguage,
  setOtherSkill,
  setPersonalSkill,
  setReadSkill,
  setRelationalSkill,
  setTechnicalSkill,
  setWriteSkill,
  //update
  setUpdateDrivingLicense,
  setUpdateNativeLanguage,
  setUpdateOralExpressionSkill,
  setUpdateOrganizationalSkill,
  setUpdateOtherLanguage,
  setUpdateOtherSkill,
  setUpdatePersonalSkill,
  setUpdateReadSkill,
  setUpdateRelationalSkill,
  setUpdateTechnicalSkill,
  setUpdateWriteSkill,
  //error
  setErrorLabel,
  resetErrorLabel,
  setPMId,
  resetOtherInfoCreateStatus,
  addOthInfoInstance,
  resetOthInfoInstance,
  resetDeleteStatus,
  resetGetByidStatus,
  resetOtherInfoCreateForm,
  resetUpdateStatus,
  resetOtherInfoUpdateForm,
  resetOtherInfoValidationStatus,
  setUpdateRequest
} = PMOtherInfo.actions

export default PMOtherInfo.reducer