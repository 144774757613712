import clsx from "clsx"
import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Button from "../../ui/molecules/button";
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../ui/organisms/banner";
import { Tab } from "react-tabs";
import { BoxAnagrafica } from "./boxAnagrafica";
import { RightBar } from "../../ui/organisms/rightBar";
import { HorizontalTab } from "../../ui/organisms/horizontalTab";
import { CenterCompanyCreation, ContactsCreation, CustomerCreation, GetCustomersById, RappLegaleCreation, resetContactsStatus, resetCreateCompanyCenterStatus, resetCreateRappLegaleStatus, resetCreateStatus, resetCustomerByIdStatus, setCodeType, setExistingCode, setNormCustomer, setOnEditCustomer, setSelectedTab, setUserId, setValidateNewCustomerStatus, setValidateNewLegalRappStatus, validateNewCustomer, validateNewLegalRapp } from "./slice";
import { BoxRubrica } from "./boxRubrica";
import { BoxCentriAziendali } from "./boxCentriAziendali";
import { BoxTecnicoAziendale } from "./boxTecnicoAziendale";
import { SelectCustom } from "../../ui/molecules/select";
import {  normativa } from "../../utils";
import { Bio } from "../normative/bio";
import RadioButtonGroup from "../../ui/molecules/RadioButtonGroup";
import { ClientTypology } from "../../ui/organisms/clientTypology";
import { AuthorityOffices } from "../../ui/organisms/sedeDiCompetenza";
import { ProduzioneIntegrata } from "../normative/prodInteg";
import { QualitaSicura } from "../normative/safeQuality";
import { ResiduoZero } from "../normative/residuoZero";
import { GlobalGap } from "../normative/global";
import { BoxRappLegale } from "./boxRappLegale";
import { setUserBusinessTech } from "../businessTechnician/slice";
import InputSearch from "../../ui/molecules/input/InputSearch";
import { linkBusinessTech } from "../users/slice";

registerLocale('it', it)

export function NewCliente() {
    const pathname = window.location.pathname
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showSuccessRl, setShowSuccessRl] = useState<boolean>(false)
    const [showSuccessContacts, setShowSuccessContacts] = useState<boolean>(false)
    const [showSuccessCa, setShowSuccessCa] = useState<boolean>(false)
    const [normSelected, setNormSelected] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false)
    const businessTechinianState = useAppSelector(state => state.businessTechinician)
    enum CustomOptionsCode {
        NEW_CODE = 'NUOVO_CODICE',
        EXIST_CODE = 'CODICE_ESISTENTE',
    }
    const [selectedOption, setSelectedOption] = useState<CustomOptionsCode | null>(null);
    const [codeDisabled, setCodeDisabled] = useState<boolean>(true)
    const clientiState = useAppSelector(state => state.clienti)

    const handleOptionChange = (value: CustomOptionsCode) => {
        setSelectedOption(value);
        dispatch(setCodeType(value))
        if (value === 'CODICE_ESISTENTE') {
            setCodeDisabled(false)
        }
        else {
            setCodeDisabled(true)
            dispatch(setExistingCode(''))
        }
    };

    useEffect(() => {
        dispatch(setOnEditCustomer(false));
    }, [])

    useEffect(() => {
        console.log('stato validazione ', clientiState.validateNewCustomerStatus)
        if (clientiState.validateNewCustomerStatus === 'successfully') {
            dispatch(setValidateNewCustomerStatus('idle'))
        }
        else if (clientiState.validateNewCustomerStatus === 'failed') {
            setShowError(true)
        }
    }, [clientiState.validateNewCustomerStatus])

    useEffect(() => {
        if (clientiState.validateNewLegalRappStatus === 'successfully') {

            dispatch(CustomerCreation(clientiState.clienteEditCreateRequest))
            dispatch(setValidateNewLegalRappStatus('idle'))
        }
        else if (clientiState.validateNewLegalRappStatus === 'failed') {
            setShowError(true)
        }
    }, [clientiState.validateNewLegalRappStatus])

    useEffect(() => {
        if (clientiState.customerCreateResponseStatus === 'successfully' && clientiState.customerId != '' && clientiState.customerId != undefined) {
            dispatch(GetCustomersById(clientiState.customerId))
            dispatch(resetCustomerByIdStatus('idle'))

        }
    }, [clientiState.customerCreateResponseStatus, clientiState.customerId]);

    useEffect(() => {
        if (clientiState.getCustomersByIdResponseStatus === 'successfully') {
            dispatch(setUserId(clientiState.getCustomersByIdResponse?.userId))
            dispatch(RappLegaleCreation(clientiState.rappLegaleEditCreateRequest))
            dispatch(ContactsCreation(clientiState.contactsCreateRequest))
            setTimeout(() => {
                clientiState.corporateCenters.map((value) =>
                    dispatch(CenterCompanyCreation(value))
                )

            }, 1000);

            resetCreateStatus('idle')
            setShowSuccess(true)
        }

    }, [clientiState.getCustomersByIdResponseStatus]);

    useEffect(() => {
        if (clientiState.userId !== undefined && clientiState.userId.toString() !== '') {
            dispatch(setUserBusinessTech(clientiState.userId.toString()))
        }

    }, [clientiState.userId]);

    useEffect(() => {
        if (businessTechinianState.userBusinessTechnicianLink.id !== undefined && businessTechinianState.userBusinessTechnicianLink.id.toString() !== '') {
            dispatch(linkBusinessTech(businessTechinianState.userBusinessTechnicianLink))
        }

    }, [businessTechinianState.userBusinessTechnicianLink.id]);

    useEffect(() => {
        if (clientiState.customerCreateResponseStatus === 'successfully') {
            setShowSuccessRl(true)
            resetCreateRappLegaleStatus('idle')
        }
    }, [clientiState.rappLegaleCreateResponseStatus]);

    useEffect(() => {
        if (clientiState.corporateCenterCreateResponseStatus === 'successfully') {
            setShowSuccessCa(true)
            resetCreateCompanyCenterStatus('idle')
        }
    }, [clientiState.corporateCenterCreateResponseStatus]);


    useEffect(() => {
        if (clientiState.contactsCreateResponseStatus === 'successfully') {
            setShowSuccessContacts(true)
            resetContactsStatus('idle')
        }
    }, [clientiState.contactsCreateResponseStatus]);

    useEffect(() => {
        if (showSuccess && showSuccessRl && showSuccessContacts) {
            setTimeout(() => {
                setShowSuccess(false)
                setShowSuccessContacts(false)
                setShowSuccessRl(false)
            }, 2500);
        }
    }, [showSuccess, showSuccessContacts, showSuccessRl]);

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Clienti', 'Nuovo Utente']}
            headerLabel="Nuovo Utente"
            rightBar={true}
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(validateNewCustomer(clientiState.clienteEditCreateRequest))
                            dispatch(validateNewLegalRapp(clientiState.rappLegaleEditCreateRequest))
                            //dispatch(CustomerCreation(clientiState.clienteEditCreateRequest))
                        }}
                    />
                </div>
            }
        >
            <div>

                {
                    (showSuccess && showSuccessRl && showSuccessContacts) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati salvati correttamente.
                        </span>
                    </div>

                }
                {
                    showError &&

                    <Banner
                        errorsList={clientiState.errorMessages}
                        type="error"
                        visible={clientiState.validateNewCustomerStatus === 'failed'}
                        label={"Si è verificato un errore durante la creazione dell'utente"}
                        closeAction={function (): void {
                            dispatch(resetCreateStatus('idle'))
                        }}
                    />

                }

                <HorizontalTab
                    children={[
                        <>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(0)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 0,
                                        "text-label-sm text-neutral-600": clientiState.selectedTab !== 0
                                    })
                                }
                                tabIndex={"0"}
                            >
                                Anagrafica
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(1))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 1,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 1
                                    }
                                    )
                                }
                                tabIndex={"1"}
                            >
                                Centri Aziendali
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(2))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 2,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 2
                                    }
                                    )
                                }
                                tabIndex={"2"}
                            >
                                Rappresentante Legale
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(3))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 3,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 3
                                    }
                                    )
                                }
                                tabIndex={"3"}
                            >
                                Tecnico Aziendale
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(4))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 4,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 4
                                    }
                                    )
                                }
                                tabIndex={"4"}
                            >
                                Rubrica
                            </Tab>
                        </>
                    ]}
                />

            </div>
            <div style={{ display: 'flex', height: '100%' }} className="bg-brandPrimary-100">
                <div className="fieldset bg-brandPrimary-200" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                    {
                        clientiState.selectedTab === 0 &&
                        <BoxAnagrafica></BoxAnagrafica>

                    }
                    {
                        clientiState.selectedTab === 1 &&
                        <BoxCentriAziendali></BoxCentriAziendali>
                    }
                    {
                        clientiState.selectedTab === 2 &&
                        <BoxRappLegale></BoxRappLegale>

                    }
                    {
                        clientiState.selectedTab === 3 &&
                        <BoxTecnicoAziendale></BoxTecnicoAziendale>
                    }
                    {
                        clientiState.selectedTab === 4 &&
                        <BoxRubrica></BoxRubrica>
                    }
                </div>

                <div className="bg-brandPrimary-100" style={{ flex: 0.3 }}>
                    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}></div>
                    <RightBar>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px', padding: '0px 24px' }}>
                                <span className="input-label">Norma di riferimento</span>

                                <SelectCustom
                                    error={clientiState.createCustomerErrors.norm}
                                    errorLabel="Scegliere una norma"
                                    placeholder={'Norma'}
                                    options={normativa}
                                    onChange={(value) => {
                                        if (value !== undefined) {
                                            dispatch(setNormCustomer(value?.toString()))
                                            setNormSelected(value?.toString())
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <div className="mt-6" style={{ padding: '0px 24px' }}>
                                    <RadioButtonGroup
                                        name={'code_type'}
                                        options={Object.values(CustomOptionsCode)}
                                        selectedValue={selectedOption}
                                        onChange={handleOptionChange}
                                        optionLabels={{
                                            [CustomOptionsCode.NEW_CODE]: 'Nuovo Codice',
                                            [CustomOptionsCode.EXIST_CODE]: 'Codice Esistente',
                                        }}
                                    />
                                    <div className="mt-6" style={{ flex: '1 1 auto' }}>
                                        <InputSearch
                                            disabled={codeDisabled}
                                            placeholder="Codice"
                                        />
                                    </div>
                                </div>
                                <div>
                                    {
                                        normSelected === 'NORMA_BIO' && (
                                            <div className="mt-6 pt-4 borderTopSection">
                                                <Bio />
                                            </div>
                                        )
                                    }
                                    {
                                        normSelected === 'PRODUZIONE_INTEGRATA' && (
                                            <div className="mt-6 pt-4 borderTopSection">
                                                <ProduzioneIntegrata />
                                            </div>
                                        )
                                    }
                                    {
                                        normSelected === "QUALITA_SICURA" && (
                                            <div className="mt-6 pt-4 borderTopSection">
                                                <QualitaSicura />
                                            </div>
                                        )
                                    }
                                    {
                                        normSelected === "RESIDUO_ZERO" && (
                                            <div className="mt-6 pt-4 borderTopSection">
                                                <ResiduoZero />
                                            </div>
                                        )
                                    }
                                    {
                                        normSelected === "GLOBAL_GAP" && (
                                            <div className="mt-6 pt-4 borderTopSection">
                                                <GlobalGap />
                                            </div>
                                        )
                                    }
                                </div>

                                <ClientTypology />

                                <div className="borderTopSection mt-6 pt-4">
                                    <AuthorityOffices />
                                </div>

                            </div>
                        </div>
                    </RightBar>
                </div>
            </div >
        </Layout >
    )
}