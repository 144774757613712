import DatePicker from "react-datepicker"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import Input from "../../input"
import { CalendarIcon } from "../../../icons/calendar"
import { setActivationDate, setDeActivationDate } from "../../../../pages/clienti/slice"
import { format } from "date-fns"
import { useState } from "react"

export function RappLegaleDataIngUsc() {
    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()

    const [dateActivate, setDataActivate] = useState<Date | undefined>(undefined)
    const [dateDeActivate, setDataDeActivate] = useState<Date | undefined>(undefined)

    return (
        <div>
            <div className="formInOut pt-2" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                <div style={{ flex: '0.5' }}>
                    <div className="text-left">
                        <span className="input-label">Data Ingresso</span>
                    </div>
                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                        selected={clientiState.rappLegaleEditCreateRequest.activationDate}
                        customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                        onChange={
                            (date) => {
                                if (date !== null) {
                                    dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                                    setDataActivate(date)
                                }
                            }
                        }
                    />

                </div>
                <div style={{ flex: '0.5', paddingBottom: 20 }}>
                    <div className="text-left">
                        <span className="input-label">Data Uscita</span>
                    </div>
                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                        selected={clientiState.rappLegaleEditCreateRequest.deactivationDate}
                        customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                        onChange={
                            (date) => {
                                if (date !== null) {
                                    dispatch(setDeActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                                    setDataDeActivate(date)
                                }
                            }
                        }
                    />
                </div>

            </div>
        </div>
    )
}