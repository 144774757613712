import { Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect } from "react";
import { EvaluationCreation, EvaluationValidation, GetAllEvaluations, resetEvaluationCreationRequest, resetEvaluationCreationStatus, resetEvaluationError, resetEvaluationValidationStatus, setEvaluationActivityId, setEvaluationDate, setEvaluatorId, setNote, setResult } from "../slice";
import DatePicker from "react-datepicker";
import Input from "../../../../ui/molecules/input";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import { format } from "date-fns";
import { SelectCustom } from "../../../../ui/molecules/select";
import { activityOptions, OptionType, resultOptions, valutatoreOptions } from "../../../../utils";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";
import { GetByIdPersonalManagement } from "../../slice";

interface Props {
    close: () => void;
}

export function ValutazioniPopupAdd(props: Props) {
    const evaluationState = useAppSelector(state => state.PMEvaluations)
    const PMState = useAppSelector(state => state.personalManagement)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if(evaluationState.evaluationValidationStatus === 'successfully') {
            dispatch(resetEvaluationValidationStatus())
            dispatch(resetEvaluationError())
            dispatch(EvaluationCreation(evaluationState.evaluationCreationRequest));
        }
    }, [evaluationState.evaluationValidationStatus])

    useEffect(() => {
        if(evaluationState.evaluationCreationStatus === 'successfully') {
            dispatch(resetEvaluationCreationStatus());
            dispatch(resetEvaluationCreationRequest());
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
            props.close();
        }
        
    }, [evaluationState.evaluationCreationStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                            
                {/* FIRST ROW */}
                <div className="formAnagrafica " style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data valutazione</span>
                        </div>
                        <DatePicker locale="it" dateFormat="d/MM/yyyy"
                            selected={evaluationState.evaluationCreationRequest.evaluationDate}
                            customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                            onChange={
                                (date) => {
                                    if (date !== null) {
                                        dispatch(setEvaluationDate(format(new Date(date), 'yyyy-MM-dd')))
                                    }
                                }
                            }
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Esito</span>
                        </div>
                        <SelectCustom
                            error={evaluationState.evaluationError.result}
                            errorLabel="Esito mancante"
                            placeholder={'Esito'}
                            options={resultOptions}
                            onChange={(value) => dispatch(setResult(value))}
                            defaultValue={evaluationState.evaluationCreationRequest.result}
                            value={evaluationState.evaluationCreationRequest.result}
                            />
                    
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Attività da valutare</span>
                        </div>
                        <SelectCustom
                            error={evaluationState.evaluationError.evaluationActivityId}
                            errorLabel="Attività mancante"
                            placeholder={'Attività da valutare'}
                            options={activityOptions}
                            onChange={(value) => dispatch(setEvaluationActivityId(value))}
                            defaultValue={evaluationState.evaluationCreationRequest.evaluationActivityId}
                            value={evaluationState.evaluationCreationRequest.evaluationActivityId}
                            />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Valutatore</span>
                        </div>
                        <SelectCustom
                            error={evaluationState.evaluationError.evaluatorId}
                            errorLabel="Valutatore mancante"
                            placeholder={'Valutatore'}
                            options={valutatoreOptions}
                            onChange={(value) => dispatch(setEvaluatorId(value))}
                            defaultValue={evaluationState.evaluationCreationRequest.evaluatorId}
                            value={evaluationState.evaluationCreationRequest.evaluatorId}
                            />
                    
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Note sulla valutazione</span>
                        </div>
                        <TextArea onChangeText={(text) => dispatch(setNote(text))} />
                    </div>

                </div>

            </div>
            {/* BUTTONS */}
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetEvaluationError())
                        dispatch(resetEvaluationCreationRequest());
                        props.close();
                    }}
                        />
                <div className="pl-2">
                <Button 
                    size="md" 
                    variant="solid" 
                    iconPosition="off" 
                    label="Aggiungi"
                    color="orange"
                    onClick={() => {
                        console.log(evaluationState.evaluationCreationRequest);
                        dispatch(EvaluationValidation(evaluationState.evaluationCreationRequest))
                    }}
                        />
                </div>
            </div>
        </Fragment>
    )
}