import clsx from "clsx"
import { ReactElement, ReactNode } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { breadcrumbsLabel } from "../../lib/utils"
import { BellIcon } from "../icons/bell"
import { MailIcon } from "../icons/mail"
import { MenuIcon } from "../icons/menu"
import { SettingsIcon } from "../icons/settings"
import Avatar from "../molecules/avatar"
import Button from "../molecules/button"
import Breadcrumb from "../organisms/breadcrumb"
import SideNav from "../organisms/navbar"
import { MenuStatus } from "./dto"
import { setMenuStatus } from "./slice"
import { MenuItems } from "../organisms/navbar/dto"
import { Header } from "../organisms/header"

interface Props {
    children: ReactNode
    headerChildren?: ReactElement
    headerLabel?: string
    breadcrumbItems: string[]
    menuItem: MenuItems
    rightBar?: boolean
    overflow?: string
}

export function Layout(props: Props) {
    const { children } = props

    const pathname = window.location.pathname

    const layoutState = useAppSelector(state => state.layout)

    const dispatch = useAppDispatch()

    return (
        <div
            className={`antialiased min-h-screen bg-white`}
            style={{ display: 'flex', flexDirection: 'row' }}>
            <div
                onMouseEnter={() => {
                    if (layoutState.menuStatus === MenuStatus.CLOSED) {
                        dispatch(setMenuStatus(MenuStatus.HOVER))
                    }
                }}
                onMouseLeave={() => {
                    if (layoutState.menuStatus === MenuStatus.HOVER) {
                        dispatch(setMenuStatus(MenuStatus.CLOSED))
                    }
                }}
                className={
                    clsx(
                        "border-neutral-200 transition-all hidden lg:block",
                        {
                            "w-[240px]": layoutState.menuStatus === MenuStatus.OPEN || layoutState.menuStatus === MenuStatus.HOVER,
                            "w-[72px]": layoutState.menuStatus === MenuStatus.CLOSED,
                            "absolute h-[100%] bg-bgGreen": layoutState.menuStatus === MenuStatus.HOVER
                        }
                    )
                }>
                <SideNav />
            </div>
            <div
                className={
                    clsx(
                        "border-neutral-200 transition-all lg:hidden",
                        {
                            "absolute h-[100%] w-[100%]": layoutState.menuStatus === MenuStatus.HOVER,
                            "hidden": layoutState.menuStatus !== MenuStatus.HOVER
                        }
                    )
                }>
                <div
                    className="w-[100%] h-[100%] backdrop-blur-sm transition-all"
                    onClick={() => {
                        dispatch(setMenuStatus(MenuStatus.CLOSED))
                    }}
                >
                    <div
                        className="w-[240px] h-[100%] bg-white opacity-100 transition-all"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <SideNav />
                    </div>
                </div>
            </div>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                className={
                    clsx(
                        "transition-all bg-brandPrimary-850",
                        {
                            'ml-[0px] lg:ml-[72px]': layoutState.menuStatus === MenuStatus.HOVER,
                            'ml-[0px]': layoutState.menuStatus !== MenuStatus.HOVER,
                        }
                    )
                }
            >
                <div className="flex flex-row items-center justify-between px-[24px] bg-bgGreen">
                    <div className="block lg:hidden py-[20px]">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                dispatch(setMenuStatus(MenuStatus.HOVER))
                            }}
                        >
                            <MenuIcon color={""} size={24} />
                        </div>
                    </div>
                    <div className="hidden lg:flex h-[80px]">
                        <Breadcrumb path={props.breadcrumbItems}
                        />
                    </div>
                    <div className="flex flex-row gap-[8px] items-center">
                        <div className="flex flex-row gap-[4px]">
                            <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<SettingsIcon color={""} size={0} />} />
                            <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<MailIcon color={""} size={0} />} />
                            <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<BellIcon color={""} size={0} />} />
                        </div>
                        <div className="h-[40px] w-[1px] bg-neutral-200" />

                        <Avatar type="single" size={'sm'} shape='circle' imageUrl='' altTextInitials="AD" />
                    </div>
                </div>
                {
                    props.headerLabel !== undefined && (
                        <Header label={props.headerLabel}>
                            {props.headerChildren}
                        </Header>
                    )

                }

                {
                    props.rightBar ? (
                        <div style={{ flexGrow: 1, padding: !children ? '24px' : '0px 0px 0px 0px', overflow: props.overflow }} className="bg-brandPrimary-200 border-t-[1px] border-t-neutral-200">
                            {
                                !children ?
                                    <div className="w-[100%] rounded-[12px] shadow-sm border-[1px] h-[100%] border-neutral-200" /> :
                                    children
                            }
                            {/* <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="logout" color={"blue"} onClick={() => { keycloak.logout({ redirectUri: window.location.origin }); }} /> */}
                        </div>
                    ) : (
                        <div style={{ flexGrow: 1, padding: !children ? '24px' : '0px 24px', overflow: 'auto' }} className="bg-brandPrimary-200 border-t-[1px] border-t-neutral-200">
                            {
                                !children ?
                                    <div className="w-[100%] rounded-[12px] shadow-sm border-[1px] h-[100%] border-neutral-200" /> :
                                    children
                            }
                            {/* <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="logout" color={"blue"} onClick={() => { keycloak.logout({ redirectUri: window.location.origin }); }} /> */}
                        </div>
                    )
                }
            </div>
        </div>
    )
}