import clsx from "clsx"
import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Input from "../../ui/molecules/input/Input";
import Select from 'react-select'
import Button from "../../ui/molecules/button";
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { UserCreation, editUser, findUserById, resetEditUserStatusIdle, setActivationDate, setDeActivationDate, setEmail, setFiscalCode, setHeadQuarter, setName, setPec, setPhone, setRole, setSurname, setUsername } from "./slice";
import { format } from "date-fns";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { SpinnerComponent } from "../../lib/spinner";
import { role, sot } from "../../utils";
import { SelectCustom } from "../../ui/molecules/select";
import { CalendarIcon } from "../../ui/icons/calendar";
import { useNavigate } from "react-router-dom";

registerLocale('it', it)

interface Props {
    children: ReactNode
}

export function EditUser() {
    const dispatch = useAppDispatch()
    const usersState = useAppSelector(state => state.users)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (usersState.selectedUserId !== undefined && usersState.selectedUserId != '') {
            dispatch(findUserById(usersState.selectedUserId))

        }
    }, [])

    useEffect(() => {
        if (usersState.editUserStatus === 'successfully') {

            setShowSuccess(true)
            setTimeout(() => {
                dispatch(resetEditUserStatusIdle())
                //navigate('/users/')
            }, 2500);
        }
        else {
            setShowSuccess(false)
        }

        if (usersState.editUserStatus === 'failed') {

            setShowError(true)
            setTimeout(() => {
                dispatch(resetEditUserStatusIdle())
                
            }, 2500);
        }
        else {
            setShowError(false)
        }

    }, [usersState.editUserStatus])

    useEffect(() => {

        if (usersState.findUserByIdResponse !== undefined && usersState.findUserByIdStatus === 'successfully') {
            dispatch(setName(usersState.findUserByIdResponse.name))
            dispatch(setSurname(usersState.findUserByIdResponse.surname))
            dispatch(setEmail(usersState.findUserByIdResponse.email))
            dispatch(setUsername(usersState.findUserByIdResponse.username))
            dispatch(setPhone(usersState.findUserByIdResponse.phone))
            dispatch(setRole(usersState.findUserByIdResponse.role))
            dispatch(setPec(usersState.findUserByIdResponse.pec))
            dispatch(setFiscalCode(usersState.findUserByIdResponse.fiscalCode))
            dispatch(setHeadQuarter(usersState.findUserByIdResponse.headQuarter))
            dispatch(setActivationDate(usersState.findUserByIdResponse.activationDate))
            dispatch(setDeActivationDate(usersState.findUserByIdResponse.deactivationDate))

        }
    }, [usersState.findUserByIdResponse])

    const [startDate, setStartDate] = useState(new Date());

    const [dateActivate, setDataActivate] = useState<Date | undefined>(undefined)
    const [dateDeActivate, setDataDeActivate] = useState<Date | undefined>(undefined)

    if (usersState.findUserByIdStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Utenti', 'Modifica Utente']}
            headerLabel={usersState.findUserByIdResponse?.name + ' ' + usersState.findUserByIdResponse?.surname}
            rightBar={true}
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Modifica" color={"orange"}
                        onClick={() => {

                            dispatch(editUser({ id: usersState.selectedUserId, data: usersState.usersEditCreateRequest }))
                        }}
                    />

                </div>

            }
        >

            <div style={{ display: 'flex', height: '100%' }}>
                <div className="fieldset rounded-lg" style={{ flex: 0.8 }}>
                    {
                        showSuccess &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>

                    }
                    {
                        showError &&

                        <div className="gap-[8px] text-left bg-red-200" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-red-800 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Errore nel salvataggio.
                            </span>
                        </div>

                    }
                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white text-uppercase fieldsetTitle">Credenziali</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7'  }}>
                                <div style={{ flex: 0.5 }}>
                                    <Input
                                        label={'Username'}
                                        placeholder="Username"
                                        onChangeText={(text) => dispatch(setUsername(text))}
                                        defaultValue={usersState.findUserByIdResponse?.username}
                                    />
                                </div>

                            </div>
                        </>

                    </div>

                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white text-uppercase fieldsetTitle">Anagrafica</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7'  }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Nome'}
                                        placeholder="Nome"
                                        onChangeText={(text) => dispatch(setName(text))}
                                        defaultValue={usersState.findUserByIdResponse?.name}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Cognome'}
                                        placeholder="Cognome"
                                        onChangeText={(text) => dispatch(setSurname(text))}
                                        defaultValue={usersState.findUserByIdResponse?.surname}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Codice Fiscale'}
                                        placeholder="Codice Fiscale"
                                        onChangeText={(text) => dispatch(setFiscalCode(text))}
                                        defaultValue={usersState.findUserByIdResponse?.fiscalCode}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7'  }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Email'}
                                        placeholder="Email"
                                        onChangeText={(text) => dispatch(setEmail(text))}
                                        defaultValue={usersState.findUserByIdResponse?.email}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'PEC'}
                                        placeholder="PEC"
                                        onChangeText={(text) => dispatch(setPec(text))}
                                        defaultValue={usersState.findUserByIdResponse?.pec}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Cellulare'}
                                        placeholder="Cellulare"
                                        onChangeText={(text) => dispatch(setPhone(text))}
                                        defaultValue={usersState.findUserByIdResponse?.phone}
                                    />
                                </div>
                            </div>
                        </>

                    </div>

                </div>
                <div className="bg-brandPrimary-100" style={{ flex: 0.2, padding: '24px', borderLeft: '1px solid #E4E4F7' }}>
                    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                        <div style={{ flex: 1 }}>
                            <SelectCustom
                                defaultValue={usersState.findUserByIdResponse?.headQuarter}
                                placeholder={'Sede'}
                                options={sot}
                                onChange={(value) => {
                                    dispatch(setHeadQuarter(value))
                                }}

                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <SelectCustom placeholder={'Ruolo'} options={role}
                                defaultValue={usersState.findUserByIdResponse?.role}
                                onChange={(value) => {
                                    dispatch(setRole(value))
                                }} />
                        </div>
                        <div>
                            <table className="reviewPermissionTable">
                                <tr>
                                    <td className="label" style={{ borderTopLeftRadius: '8px' }}>Ufficio</td>
                                    <td className="value" ></td>
                                </tr>
                                <tr>
                                    <td className="label">Normativa</td>
                                    <td className="value"></td>
                                </tr>
                                <tr>
                                    <td className="label">Regioni</td>
                                    <td className="value"></td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ display: 'flex', textAlign: 'left', gap: '8px', flexDirection: 'column' }}>
                            <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input fieldsetTitle">Data Attivazione</span>
                            </div>
                            <div>
                                <DatePicker locale="it" dateFormat="d/MM/yyyy"
                                    customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                    selected={
                                        dateActivate === undefined ? usersState.findUserByIdResponse?.activationDate : dateActivate}
                                    onChange={
                                        (date) => {
                                            if (date !== null) {
                                                dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')));
                                                setDataActivate(date)

                                            }
                                        }
                                    }

                                />
                            </div>

                            <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input fieldsetTitle">Data Disattivazione</span>
                            </div>
                            <div>
                                <DatePicker locale="it"
                                    disabled
                                    customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                    dateFormat="d/MM/yyyy"
                                    selected={
                                        dateDeActivate === undefined ? usersState.findUserByIdResponse?.deactivationDate : dateDeActivate}
                                    onChange={
                                        (date) => {
                                            if (date !== null) {
                                                dispatch(setDeActivationDate(format(new Date(date), 'yyyy-MM-dd')));
                                                setDataDeActivate(date)
                                            }
                                        }
                                    }
                                />
                            </div>

                        </div>

                    </div>

                </div>
            </div>



        </Layout>
    )
}