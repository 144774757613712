import { ReactNode, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import { keycloak } from "../../lib/keycloak"
import './style.scss';
import Select from 'react-select'
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { SelectCustom } from "../../ui/molecules/select";
import { status, sot, normativa } from "../../utils";
import { ClientiList } from "./list";
import Input from "../../ui/molecules/input/Input";
import { GetCustomers, setFindAllCustomersFiltersCompanyName, setFindAllCustomersFiltersNorm, setFindAllCustomersFiltersPage, setFindAllCustomersFiltersSearch } from "./slice";
import { Pagination } from "../../ui/organisms/pagination";

interface Props {
    children: ReactNode
}

export function Clienti() {

    const clientiState = useAppSelector(state => state.clienti)
    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchCliente, setSearchCliente] = useState('')
    const selectInputRef = useRef<Select>(null);
    const [selectedValue, setSelectedValue] = useState<string | undefined>('');

    useEffect(() => {
        dispatch(GetCustomers(clientiState.filters))
    }, [])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Clienti']}
            headerLabel="Lista Clienti"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Cliente" color={"orange"}
                        onClick={() => {
                            navigate('/cliente')
                        }}
                    />
                    <div style={{ display: 'none' }} >
                        <Button size={"sm"} iconPosition={"off"} variant={"ghost"} label="logout" color={"blue"} onClick={() => { keycloak.logout({ redirectUri: window.location.origin }); }} />
                    </div>

                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 0px 0px 0px' }}>
                <div className="flex flex-row gap-[16px]" style={{ flex: 1, alignItems: 'center' }}>
                    <div style={{justifyContent:'left'}} className="flex gap-[16px]">
                        <div>
                            <Input
                                placeholder="Cerca Codice Cliente, CUAA o Ragione Sociale"
                                onChangeText={(text) =>
                                    dispatch(setFindAllCustomersFiltersSearch(text))
                                }
                            />
                        </div>
                        <div>
                            <SelectCustom
                                placeholder={'Sede'}
                                options={sot}
                                onChange={(value) => {

                                }}
                            />
                        </div>
                        <div>
                            <SelectCustom
                                ref={selectInputRef}
                                placeholder={'Norma'}
                                options={normativa}
                                value={selectedValue}
                                defaultValue={selectedValue}
                                onChange={(value) => {
                                    dispatch(setFindAllCustomersFiltersNorm(value))
                                    if (value !== undefined)
                                        setSelectedValue(value)
                                }}
                            />

                        </div>

                        <div >
                            <SelectCustom
                                placeholder={'Stato'}
                                options={status}
                                onChange={(value) => {

                                }}
                            />

                        </div>
                        <div>
                            <span>
                                <Button
                                    size={"lg"}
                                    iconPosition={"off"}
                                    variant={"link"}
                                    label="Pulisci"
                                    color={"gray"}
                                    onClick={
                                        () => {
                                            dispatch(setFindAllCustomersFiltersNorm(''))
                                            dispatch(setFindAllCustomersFiltersSearch(''))
                                            setSelectedValue(undefined)
                                        }
                                    }
                                />
                            </span>
                        </div>

                        <div>
                            <span>
                                <Button
                                    size={"lg"}
                                    iconPosition={"off"}
                                    variant={"outline"}
                                    label="Cerca"
                                    color={"blue"}
                                    onClick={
                                        () => {
                                            dispatch(GetCustomers(clientiState.filters))
                                        }
                                    }
                                />
                            </span>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'right', flex: 1}}>

                        <Pagination
                            numbResults={clientiState.getCustomersResponse ? clientiState.getCustomersResponse.total : 0}
                            pages={clientiState.getCustomersResponse?.totalPage || 1}
                            currentPage={clientiState.filters.page ? clientiState.filters.page : 0}
                            detailsResults={'clienti'}
                            setPage={
                                (page) => {
                                    dispatch(setFindAllCustomersFiltersPage(page))
                                }
                            }

                        />
                    </div>


                </div>


            </div>
            <ClientiList></ClientiList>
        </Layout>
    )
}