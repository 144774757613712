import { ReactNode, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import { keycloak } from "../../lib/keycloak"
import './style.scss';
import Select from 'react-select'
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { ListingPersonale } from "./list";
import { GetAllPersonalManagement, PMCreation, resetPersonalManagementCreationStatus } from "./slice";

interface Props {
    children: ReactNode
}

export function GestionePersonale() {

    const PMState = useAppSelector(state => state.personalManagement)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(PMState.personalManagementCreationStatus === 'successfully') {
            dispatch(resetPersonalManagementCreationStatus())
            dispatch(GetAllPersonalManagement())
            navigate('/nuovoPersonale')
        }
    }, [PMState.personalManagementCreationStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Personale']}
            headerLabel="Gestione Personale"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Personale" color={"orange"}
                        onClick={() => {
                            dispatch(PMCreation())
                        }}
                    />
                    <div style={{ display: 'none' }} >
                        <Button size={"sm"} iconPosition={"off"} variant={"ghost"} label="logout" color={"blue"} onClick={() => { keycloak.logout({ redirectUri: window.location.origin }); }} />
                    </div>

                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 0px 0px 0px' }}>
                <ListingPersonale />
            </div>
        
        </Layout>
    )
}