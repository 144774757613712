import { GetAllPersonalManagementDTO, PersonalManagementDTO } from "./dto"
import { PersonalManagementServiceImpl } from "./serviceImpl"

export interface PersonalManagementService {
    CreatePersonalManagementResource(): Promise<string>
    GetAllPersonalManagementResources(): Promise<GetAllPersonalManagementDTO>
    GetByIdPersonalManagementResources(id:string): Promise<PersonalManagementDTO>
}

export function NewPersonalManagementService(): PersonalManagementService {
    return new PersonalManagementServiceImpl();
}