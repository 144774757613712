import clsx from "clsx"
import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Button from "../../ui/molecules/button";
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../ui/organisms/banner";
import { Tab } from "react-tabs";
import { HorizontalTab } from "../../ui/organisms/horizontalTab";

import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus, setSelectedTab } from "./slice";
import { BoxAnagrafica } from "./boxAnagrafica/boxAnagrafica";
import { BoxValutazioni } from "./boxValutazioni/boxValutazioni";
import { BoxContratti } from "./boxContratti/boxContratti";
import BoxOtherData from "./boxOtherData/boxOtherData";
import { BoxCDXM026 } from "./boxCDXM026/boxCDXM026";
import BoxOtherInfo from "./boxOtherInfo/boxOtherInfo";
import { resetOtherInfoCreateStatus } from "./boxOtherInfo/slice";
import { AnagraphicCreate, resetAnagraphicErrors, setValidateAnagraphicCreationStatus, validateAnagraphicCreation } from "./boxAnagrafica/slice";

registerLocale('it', it)

export function NewStaff() {
    const personalManagement = useAppSelector(state => state.personalManagement)
    const otherInfoState = useAppSelector(state => state.PMOtherInfo)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const evaluationsState = useAppSelector(state => state.PMEvaluations)
    const contractsState = useAppSelector(state => state.PMContracts)
    const otherDataState = useAppSelector(state => state.PMOtherData)
    const CDXM026State = useAppSelector(state => state.PMCDXM026)
    
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false)
    const [showSuccessRl, setShowSuccessRl] = useState<boolean>(false)

    const [showError, setShowError] = useState<boolean>(false)

    useEffect(() => {
        if(personalManagement.getByIdPersonalManagementResourcesStatus ==='successfully')
        {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
        }
        
    }, [personalManagement.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(anagraficaState.validateAnagraphicCreationStatus === 'successfully') {
            dispatch(setValidateAnagraphicCreationStatus('idle'))
            dispatch(resetAnagraphicErrors())
            dispatch(AnagraphicCreate(anagraficaState.createAnagraphicRequest))
        }
    }, [anagraficaState.validateAnagraphicCreationStatus])

    // CREATION SUCCESS BANNER
    useEffect(() => {
        if(evaluationsState.evaluationCreationStatus === 'successfully' ||
            contractsState.contractCreationStatus === 'successfully' ||
            otherDataState.otherDataCreationStatus === 'successfully' ||
            CDXM026State.CDXM026CreationStatus === 'successfully' ||
            anagraficaState.AnagraphicCreationStatus === 'successfully' ||
            otherInfoState.createOtherInfoStatus === 'successfully'
        ) {
            setShowSuccess(true)
        }
    }, [evaluationsState.evaluationCreationStatus,
        contractsState.contractCreationStatus,
        otherDataState.otherDataCreationStatus,
        CDXM026State.CDXM026CreationStatus,
        otherInfoState.createOtherInfoStatus,
        anagraficaState.AnagraphicCreationStatus
    ])

    // UPDATE SUCCESS BANNER
    useEffect(() => {
        if(evaluationsState.evaluationUpdateStatus === 'successfully' ||
            contractsState.contractUpdateStatus === 'successfully' ||
            otherDataState.otherDataUpdateStatus === 'successfully' ||
            CDXM026State.CDXM026UpdateStatus === 'successfully' ||
            otherInfoState.OthInfoUpdateStatus === 'successfully'
        ) {
            setShowSuccess(true)
        }
    }, [
        evaluationsState.evaluationUpdateStatus,
        contractsState.contractUpdateStatus,
        otherDataState.otherDataUpdateStatus,
        CDXM026State.CDXM026UpdateStatus,
        otherInfoState.OthInfoUpdateStatus,
    ])

    // DELETE SUCCESS BANNER
    useEffect(() => {
        if(evaluationsState.evaluationDeleteStatus === 'successfully' ||
            contractsState.contractDeleteStatus === 'successfully' ||
            otherDataState.otherDataDeleteStatus === 'successfully' ||
            CDXM026State.CDXM026DeleteStatus === 'successfully' ||
            otherInfoState.DeleteIdOtherInfoStatus === 'successfully'
        ) {
            setShowDeleteSuccess(true)
        }
    }, [
        evaluationsState.evaluationDeleteStatus,
        contractsState.contractDeleteStatus,
        otherDataState.otherDataDeleteStatus,
        CDXM026State.CDXM026DeleteStatus,
        otherInfoState.DeleteIdOtherInfoStatus,
    ])

    useEffect(() => {
        if(showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
        if(showDeleteSuccess) {
            setTimeout(() => {
                setShowDeleteSuccess(false)
            }, 2000)
        }
    }, [showSuccess, showDeleteSuccess])


    return (
        <Layout menuItem={MenuItems.GESTIONE_PERSONALE} breadcrumbItems={['Dashboard', 'Gestione Personale', 'Nuovo Utente']}
            headerLabel="Nuovo Utente"
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(validateAnagraphicCreation(anagraficaState.createAnagraphicRequest))
                            //dispatch(AnagraphicCreate(anagraficaState.createAnagraphicRequest))
                        }}
                    />
                </div>
            }
        >
            <div>

                {
                    (showSuccess) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati salvati correttamente.
                        </span>
                    </div>

                }
                {
                    (showDeleteSuccess) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati eliminati correttamente.
                        </span>
                    </div>

                }


                <HorizontalTab
                    children={[
                        <>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(0)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 0,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 0
                                    })
                                }
                                tabIndex={"0"}
                            >
                                Anagrafica
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(1)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 1,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 1
                                    })
                                }
                                tabIndex={"1"}
                            >
                                Requisiti
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(2)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 2,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 2
                                    })
                                }
                                tabIndex={"2"}
                            >
                                Funzioni
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(3)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 3,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 3
                                    })
                                }
                                tabIndex={"3"}
                            >
                                Valutazioni
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(4)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 4,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 4
                                    })
                                }
                                tabIndex={"4"}
                            >
                                Contratti
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(5)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 5,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 5
                                    })
                                }
                                tabIndex={"5"}
                            >
                                Altri dati
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(6)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 6,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 6
                                    })
                                }
                                tabIndex={"6"}
                            >
                                CDX M026
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(7)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 7,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 7
                                    })
                                }
                                tabIndex={"7"}
                            >
                                Altre Informazioni
                            </Tab>

                        </>
                    ]}
                />

            </div>
            <div style={{ display: 'flex', height: '100%' }} className="bg-brandPrimary-100">
                <div className="fieldset bg-brandPrimary-200" style={{ flex: 1, borderTopRightRadius: '16px' }}>
                    {
                        personalManagement.selectedTab === 0 &&
                        <BoxAnagrafica />
                    }
                    {
                        personalManagement.selectedTab === 3 &&
                        <BoxValutazioni />
                    }
                    {
                        personalManagement.selectedTab === 4 &&
                        <BoxContratti />
                    }
                    {
                        personalManagement.selectedTab === 5 &&
                        <BoxOtherData />
                    }
                    {
                        personalManagement.selectedTab === 6 &&
                        <BoxCDXM026 />
                    }
                    {
                        personalManagement.selectedTab === 7 &&
                        <BoxOtherInfo />
                    }

                </div>
            </div >
        </Layout >
    )
}