import { HeaderProps } from "./dto";

export function Header(props: HeaderProps) {
    return (
        <div className="bg-brandPrimary-800" style={{ height: '64px', padding: '0px 24px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '24px', borderBottom: '1px solid rgba(228, 228, 247, 1)', borderTopLeftRadius:'24px'}}>
            <div className="text-heading-sm font-bold text-white sectionTitle" style={{ width: 'max-content', display: 'block' }}>{props.label}</div>
            <div className="h-[100%] flex-1">
                {props.children}
            </div>
        </div>
    )
}