import { ReactNode, useEffect } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import './style.scss';
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../ui/organisms/pagination";
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus, setCurrentPersonalManagementId } from "./slice";
import { GetByIdAnagraphic, addPersonalManagementInstance, resetGetByIdStatus, resetPersonalManagementInstance } from "./boxAnagrafica/slice";
import { Dropdown } from "../../ui/organisms/dropdown";
import { EditIcon } from "../../ui/icons/edit";
import { formatterDate } from "../../utils";
import { ActiveIcon } from "../../ui/icons/activeIcon";
import { InactiveIcon } from "../../ui/icons/inactiveIcon";

interface Props {
    children: ReactNode
}

export function ListingPersonale() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const personalManagement = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)

    useEffect(() => {
        dispatch(GetAllPersonalManagement())
        dispatch(resetPersonalManagementInstance())
    }, [])

    useEffect(() => {
        if (personalManagement.getAllPersonalManagementResourcesStatus === 'successfully') {
            dispatch(resetGetAllPersonalManagementResourcesStatus())
            if (personalManagement.getAllPersonalManagementResourcesResponse) {
                for (let index = 0; index < personalManagement.getAllPersonalManagementResourcesResponse.data.length; index++) {
                    if (personalManagement.getAllPersonalManagementResourcesResponse.data[index].anagraphicId) {
                        const idAnagraphic = personalManagement.getAllPersonalManagementResourcesResponse.data[index].anagraphicId
                        if (idAnagraphic) {
                            dispatch(GetByIdAnagraphic(idAnagraphic.toString()))
                        }
                    }

                }
            }
        }
    }, [personalManagement.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if (anagraficaState.GetByIdAnagraphicStatus === 'successfully') {
            dispatch(resetGetByIdStatus('idle'))
            dispatch(addPersonalManagementInstance(anagraficaState.getByIdAnagraphicRequest))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    return (
        <div className="w-[100%]">
            <div style={{ padding: '24px 0' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}>#</th>
                            <th>Sede</th>
                            <th>Data Inizio</th>
                            <th>Data Fine</th>
                            <th>Cognome</th>
                            <th>Nome</th>
                            <th>Cod. Id.</th>
                            <th>Regione</th>
                            <th>Prov.</th>
                            <th>Comune</th>
                            <th>Email</th>
                            <th>PEC</th>
                            <th>Recapito</th>
                            <th style={{ textAlign: 'center' }}>Stato</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {anagraficaState.getByIdPMinstance.map(user =>
                            <tr
                                className="bg-white h-[52px]  border-b-[1px] border-b-neutral-200"
                                key={user.id}
                            >
                                <td>{user.numScheda}</td>
                                <td></td>
                                <td className="text-neutral-700">{formatterDate(user.starting)}</td>
                                <td className="text-neutral-700">{formatterDate(user.ending)}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.name}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.surname}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.identifierCode}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.region}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.province}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.city}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.firstEmail}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.pec}</td>
                                <td className="text-blue-800 robotoBold text-left">{user.firstPhone}</td>
                                <td>
                                    {
                                        user.active ?
                                            (
                                                <ActiveIcon></ActiveIcon>
                                            )
                                            :
                                            (
                                                <InactiveIcon></InactiveIcon>
                                            )
                                    }
                                </td>

                                <td className="w-[20px] cursor-pointer">
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                                        <Dropdown items={[
                                            {
                                                label: 'Modifica',
                                                icon: <EditIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(setCurrentPersonalManagementId(user.personalManagementId))
                                                    navigate('/editPm/' + user.personalManagementId)
                                                }
                                            },

                                        ]} />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* <Pagination
                    numbResults={usersState.getUsersResponse ? usersState.getUsersResponse?.total : 0}
                    detailsResults={'utenti'}
                    pages={usersState.getUsersResponse?.totalPage || 1}
                    currentPage={usersState.filters.page} setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))}
                /> */}
            </div>
        </div>
    )
}