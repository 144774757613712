import clsx from "clsx"
import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Input from "../../ui/molecules/input/Input";
import Button from "../../ui/molecules/button";
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { UserCreation, resetCreationStatusIdle, setActivationDate, setEmail, setFiscalCode, setHeadQuarter, setName, setPec, setPhone, setRole, setSurname, setUsername } from "./slice";
import { format } from "date-fns";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { mapRole, role, sot } from "../../utils";
import { SelectCustom } from "../../ui/molecules/select";
import { CalendarIcon } from "../../ui/icons/calendar";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../ui/organisms/banner";

registerLocale('it', it)

interface Props {
    children: ReactNode
}

export function User() {
    const pathname = window.location.pathname
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [startDate, setStartDate] = useState(new Date());
    const usersState = useAppSelector(state => state.users)

    const [dateActivate, setDataActivate] = useState<Date | undefined>(undefined)
    const [dateDeActivate, setDataDeActivate] = useState<Date | undefined>(undefined)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [roleTable, setRoleTable] = useState('')
    const [roleRegione, setRoleRegione] = useState('')
    const [roleNorma, setRoleNorma] = useState('')
    const [roleSelect, setRoleSelect] = useState<string | boolean | undefined>(undefined)
    useEffect(() => {
        if (roleSelect != '' && roleSelect !== undefined && roleSelect) {
            mapRole.filter(({ role }) => role === roleSelect).map(el => {
                setRoleTable(el.label)
                setRoleRegione(el.regioni)
                setRoleNorma(el.normativa)
            })
        }


    }, [roleSelect])




    useEffect(() => {
        if (usersState.userCreationStatus === 'successfully') {

            setShowSuccess(true)
            setTimeout(() => {
                dispatch(resetCreationStatusIdle())
                navigate('/users/')
            }, 2500);
        }
        else {
            setShowSuccess(false)
        }

        if (usersState.userCreationStatus === 'failed') {

            setShowError(true)
            setTimeout(() => {
                dispatch(resetCreationStatusIdle())
            }, 2500);
        }
        else {
            setShowError(false)
        }

    }, [usersState.userCreationStatus])


    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Utenti', 'Nuovo Utente']}
            headerLabel="Nuovo Utente"
            rightBar={true}
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(UserCreation(usersState.usersEditCreateRequest))
                        }}
                    />

                </div>

            }
        >

            <div style={{ display: 'flex', height: '100%' }}>
                <div className="fieldset rounded-lg" style={{ flex: 0.8 }}>
                    {
                        showSuccess &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>

                    }
                    <Banner
                        type="error"
                        visible={usersState.userCreationStatus === 'failed'}
                        label={"Si è verificato un errore durante la creazione dell'utente"}
                        closeAction={function (): void {
                            dispatch(resetCreationStatusIdle())
                        }}
                    />


                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white fieldsetTitle text-uppercase">Credenziali</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.5 }}>
                                    <Input label={'Username'} placeholder="Username" onChangeText={(text) => dispatch(setUsername(text))} />
                                </div>

                            </div>
                        </>

                    </div>

                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white text-uppercase fieldsetTitle">Anagrafica</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input supportingText={usersState.createUserErrors.name ? "Inserire il nome." : undefined} error={usersState.createUserErrors.name} label={'Nome'} placeholder="Nome" onChangeText={(text) => dispatch(setName(text))} />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input supportingText={usersState.createUserErrors.surname ? "Inserire il cognome." : undefined} error={usersState.createUserErrors.surname} label={'Cognome'} placeholder="Cognome" onChangeText={(text) => dispatch(setSurname(text))} />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input supportingText={usersState.createUserErrors.surname ? "Inserire il codice fiscale." : undefined} error={usersState.createUserErrors.fiscalCode} label={'Codice Fiscale'} placeholder="Codice Fiscale" onChangeText={(text) => dispatch(setFiscalCode(text))} />
                                </div>
                            </div>

                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7'  }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input supportingText={usersState.createUserErrors.email ? "Inserire il codice fiscale." : undefined} error={usersState.createUserErrors.email} label={'Email'} placeholder="Email" onChangeText={(text) => dispatch(setEmail(text))} />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input label={'PEC'} placeholder="PEC" onChangeText={(text) => dispatch(setPec(text))} />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input  label={'Cellulare'} placeholder="Cellulare" onChangeText={(text) => dispatch(setPhone(text))} />
                                </div>
                            </div>
                        </>

                    </div>

                </div>
                <div className="bg-brandPrimary-100" style={{ flex: 0.2, padding: '24px', borderLeft: '1px solid #E4E4F7' }}>
                    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                        <div style={{ flex: 1 }}>
                            <SelectCustom
                                placeholder={'Sede'}
                                options={sot}
                                onChange={(value) => {
                                    dispatch(setHeadQuarter(value))
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <SelectCustom
                                placeholder={'Ruolo'}
                                options={role}
                                onChange={(value) => {
                                    dispatch(setRole(value))
                                    setRoleTable('')
                                    setRoleRegione('')
                                    setRoleNorma('')
                                    setRoleSelect(value)
                                }}
                            />

                        </div>
                        <div>
                            <table className="reviewPermissionTable">
                                <tr>
                                    <td className="label" style={{ borderTopLeftRadius: '8px' }}>Ufficio</td>
                                    <td className="value" >
                                        {roleTable}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">Normativa</td>
                                    <td className="value">{roleNorma}</td>
                                </tr>
                                <tr>
                                    <td className="label">Regioni</td>
                                    <td className="value">{roleRegione}</td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ display: 'flex', textAlign: 'left', gap: '8px', flexDirection: 'column' }}>
                            <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input">Data Attivazione</span>
                            </div>
                            <div>
                                <DatePicker locale="it" dateFormat="d/MM/yyyy" selected={dateActivate}
                                    customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                    onChange={
                                        (date) => {
                                            if (date !== null) {
                                                dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')));
                                                setDataActivate(date)

                                            }
                                        }
                                    }

                                />
                            </div>
                            {/* <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input">Data Disattivazione</span>
                            </div>
                            <div>
                                <DatePicker locale="it"
                                    customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                    dateFormat="d/MM/yyyy"
                                    selected={dateDeActivate}
                                    onChange={
                                        (date) => {
                                            if (date !== null) {
                                                setDataDeActivate(date)
                                            }
                                        }
                                    }
                                />
                            </div> */}

                        </div>

                    </div>

                </div>
            </div>



        </Layout>
    )
}