import { ReactNode, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { MinusIcon } from "../../../ui/icons/minusIcon";
import { PlusIconAccordion } from "../../../ui/icons/plusIcon";
import React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Input from "../../../ui/molecules/input";
import { countries, fiscalRegimeOptions, genreSelect, OptionType, Province, Region, regions } from "../../../utils";
import { SelectCustom } from "../../../ui/molecules/select";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../../ui/icons/calendar";
import { provinceComuniMap } from "../../../utils/listaComuni";
import { resetEditStatus, resetGetByIdStatus, setAddress, setBirthCity, setBirthDate, setBirthProvince, setBirthRegion, setCity, setCityRegister, setEnding, setExternalCity, setExternalNation, setExternalProvince, setFirstEmail, setFirstFax, setFirstHouse, setFirstOffice, setFirstPhone, setFiscalCode, setFiscalRegime, setIdCode, setName, setNote, setNumRegister, setNumScheda, setPec, setPMId, setProfessionalRegister, setProvince, setRegion, setSdi, setSecondEmail, setSecondFax, setSecondHouse, setSecondOffice, setSecondPhone, setSex, setStarting, setSurname, setVat } from "./slice";
import { format } from "date-fns";
import TextArea from "../../../ui/molecules/textArea";
import { SpinnerComponent } from "../../../lib/spinner";

export function BoxAnagrafica() {


    const dispatch = useAppDispatch()
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const PMState = useAppSelector(state => state.personalManagement)

    // DATI ANAGRAFICI
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [provinceOptions2, setProvinceOptions2] = useState<OptionType[]>([]);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);
    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);

    // RECAPITI
    const [provinceRPOptions, setProvinceRPOptions] = useState<OptionType[]>([]);
    const [comuneRPOptions, setComuneRPOptions] = useState<OptionType[]>([]);
    const [selectedRPRegion, setSelectedRPRegion] = useState<OptionType | null>(null);
    const [selectedRPProvince, setSelectedRPProvince] = useState<OptionType | null>(null);
    const [selectedRPComune, setSelectedRPComune] = useState<OptionType | null>(null);
    const [showAnagrafica, setAnagrafica] = useState<boolean>(false);


    const [expanded, setExpanded] = React.useState<string | false>("panel1");

    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const regionOptions: OptionType[] = regions.map((region: Region) => ({
        value: region.name,
        label: region.name
    }));

    useEffect(() => {
        dispatch(setPMId(PMState.currentPersonalManagementId))
        if(PMState.currentPersonalManagementId) {
            setAnagrafica(true)
        }
    }, [])

    useEffect(() => {
        if (anagraficaState.AnagraphicEditStatus === 'successfully') {
            dispatch(resetEditStatus('idle'))
        }
    }, [anagraficaState.AnagraphicEditStatus])

    useEffect(() => {
        if (anagraficaState.GetByIdAnagraphicStatus === 'successfully') {

            if (anagraficaState.getByIdAnagraphicRequest) {
                if (anagraficaState.getByIdAnagraphicRequest.birthRegion) {
                    const foundRegion = regions.find((region: Region) => region.name === anagraficaState.getByIdAnagraphicRequest.birthRegion);
                    if (foundRegion) {

                        const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                            value: province.code,
                            label: province.name
                        }));

                        setProvinceOptions(newProvinceOptions);
                    } else {
                        setProvinceOptions([]);
                    }
                } else {
                    setProvinceOptions([]);
                }

                if (anagraficaState.getByIdAnagraphicRequest?.birthProvince) {
                    const comuni = provinceComuniMap.get(anagraficaState.getByIdAnagraphicRequest?.birthProvince) || [];
                    const newComuneOptions = comuni.map((comune: string) => ({
                        value: comune,
                        label: comune
                    }));
                    setComuneOptions(newComuneOptions);
                } else {
                    setComuneOptions([]);
                }

                dispatch(setNumScheda(anagraficaState.getByIdAnagraphicRequest.numScheda))
                dispatch(setIdCode(anagraficaState.getByIdAnagraphicRequest.identifierCode))
                dispatch(setName(anagraficaState.getByIdAnagraphicRequest.name))
                dispatch(setSurname(anagraficaState.getByIdAnagraphicRequest.surname))
                dispatch(setSurname(anagraficaState.getByIdAnagraphicRequest.surname))
                dispatch(setSex(anagraficaState.getByIdAnagraphicRequest.sex))
                dispatch(setBirthDate(anagraficaState.getByIdAnagraphicRequest.birth))
                dispatch(setBirthRegion(anagraficaState.getByIdAnagraphicRequest.birthRegion))
                dispatch(setBirthProvince(anagraficaState.getByIdAnagraphicRequest.birthProvince))
                dispatch(setBirthCity(anagraficaState.getByIdAnagraphicRequest.city))
                dispatch(setExternalNation(anagraficaState.getByIdAnagraphicRequest.externalNation))
                dispatch(setExternalProvince(anagraficaState.getByIdAnagraphicRequest.externalProvince))
                dispatch(setExternalCity(anagraficaState.getByIdAnagraphicRequest.externalCity))
                dispatch(setAddress(anagraficaState.getByIdAnagraphicRequest.address))
                dispatch(setRegion(anagraficaState.getByIdAnagraphicRequest.region))
                dispatch(setFirstEmail(anagraficaState.getByIdAnagraphicRequest.firstEmail))
                dispatch(setSecondEmail(anagraficaState.getByIdAnagraphicRequest.secondEmail))
                dispatch(setPec(anagraficaState.getByIdAnagraphicRequest.pec))
                dispatch(setFirstHouse(anagraficaState.getByIdAnagraphicRequest.firstHouse))
                dispatch(setSecondHouse(anagraficaState.getByIdAnagraphicRequest.secondHouse))
                dispatch(setFirstOffice(anagraficaState.getByIdAnagraphicRequest.firstOffice))
                dispatch(setSecondOffice(anagraficaState.getByIdAnagraphicRequest.secondHouse))
                dispatch(setCityRegister(anagraficaState.getByIdAnagraphicRequest.cityRegister))
                dispatch(setFirstFax(anagraficaState.getByIdAnagraphicRequest.firstFax))
                dispatch(setFirstPhone(anagraficaState.getByIdAnagraphicRequest.firstPhone))
                dispatch(setFiscalCode(anagraficaState.getByIdAnagraphicRequest.fiscalCode))
                dispatch(setFiscalRegime(anagraficaState.getByIdAnagraphicRequest.fiscalRegime))
                dispatch(setNote(anagraficaState.getByIdAnagraphicRequest.note))
                dispatch(setNumRegister(anagraficaState.getByIdAnagraphicRequest.numRegister))
                dispatch(setProfessionalRegister(anagraficaState.getByIdAnagraphicRequest.professionalRegister))
                dispatch(setSdi(anagraficaState.getByIdAnagraphicRequest.sdi))
                dispatch(setSecondFax(anagraficaState.getByIdAnagraphicRequest.secondFax))
                dispatch(setSecondPhone(anagraficaState.getByIdAnagraphicRequest.secondPhone))
                dispatch(setStarting(anagraficaState.getByIdAnagraphicRequest.starting))
                dispatch(setEnding(anagraficaState.getByIdAnagraphicRequest.ending))
                dispatch(setVat(anagraficaState.getByIdAnagraphicRequest.vat))
                dispatch(setPMId(anagraficaState.getByIdAnagraphicRequest.personalManagementId))
                setAnagrafica(true)

            }
            dispatch(resetGetByIdStatus('idle'))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])


    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedRPRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRPRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceRPOptions(newProvinceOptions);
            } else {
                setProvinceRPOptions([]);
            }
        } else {
            setProvinceRPOptions([]);
        }
    }, [selectedRPRegion]);

    useEffect(() => {
        if (selectedRPProvince) {
            const comuni = provinceComuniMap.get(selectedRPProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneRPOptions(newComuneOptions);
        } else {
            setComuneRPOptions([]);
        }
    }, [selectedRPProvince]);

    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleRpRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRPRegion(selectedOption);
        setSelectedRPProvince(null); // Reset province when region changes
        setSelectedRPComune(null);
    };

    const handleRpProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedRPProvince(selectedOption);
        setSelectedRPComune(null);
    };


    if (!showAnagrafica) {
        return (
            <SpinnerComponent size={'large'} />
        )
    }


    return (
        <div style={{ padding: '24px' }} id="boxAnagrafica">
            <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel1' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel1-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Dati anagrafici</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">
                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Scheda n.'}
                                        placeholder="Specificare numero..."
                                        error={anagraficaState.anagraphicErrors.numScheda}
                                        supportingText={anagraficaState.anagraphicErrors.numScheda ? "Inserire Numero Scheda." : undefined}
                                        value={anagraficaState.createAnagraphicRequest.numScheda}
                                        defaultValue={anagraficaState.createAnagraphicRequest.numScheda}
                                        onChangeText={(text) => { dispatch(setNumScheda(text)) }}
                                    />

                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Codice identificativo del tecnico ispettore'}
                                        placeholder="Specificare numero..."
                                        value={anagraficaState.createAnagraphicRequest.identifierCode}
                                        defaultValue={anagraficaState.createAnagraphicRequest.identifierCode}
                                        onChangeText={(text) => { dispatch(setIdCode(text)) }}
                                    />

                                </div>
                            </div>
                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Cognome'}
                                        placeholder="Cognome"
                                        error={anagraficaState.anagraphicErrors.surname}
                                        supportingText={anagraficaState.anagraphicErrors.surname ? "Inserire un cognome" : undefined}
                                        value={anagraficaState.createAnagraphicRequest.surname}
                                        defaultValue={anagraficaState.createAnagraphicRequest.surname}
                                        onChangeText={(text) => { dispatch(setSurname(text)) }}
                                    />

                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Nome'}
                                        placeholder="Nome"
                                        error={anagraficaState.anagraphicErrors.name}
                                        supportingText={anagraficaState.anagraphicErrors.name ? "Inserire un nome." : undefined}
                                        value={anagraficaState.createAnagraphicRequest.name}
                                        defaultValue={anagraficaState.createAnagraphicRequest.name}
                                        onChangeText={(text) => { dispatch(setName(text)) }}
                                    />

                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Sesso</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Sesso'}
                                        options={genreSelect}
                                        onChange={(value) => dispatch(setSex(value))}
                                        
                                        defaultValue={anagraficaState.createAnagraphicRequest.sex}
                                        value={anagraficaState.createAnagraphicRequest.sex}
                                        error={anagraficaState.anagraphicErrors.sex}
                                        errorLabel="Specificare sesso"
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Data di nascita</span>
                                    </div>
                                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                                        selected={anagraficaState.createAnagraphicRequest.birth}
                                        customInput={<Input supportingText={anagraficaState.anagraphicErrors.birth ? "Inserire data di nascita." : undefined} error={anagraficaState.anagraphicErrors.birth} startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                        onChange={
                                            (date) => {
                                                if (date !== null) {
                                                    dispatch(setBirthDate(format(new Date(date), 'yyyy-MM-dd')))
                                                }
                                            }
                                        }
                                    />

                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Regione di nascita</span>
                                    </div>
                                    <SelectCustom
                                        defaultValue={anagraficaState.createAnagraphicRequest.birthRegion}
                                        value={anagraficaState.createAnagraphicRequest.birthRegion}
                                        error={anagraficaState.anagraphicErrors.birthRegion}
                                        errorLabel="Inserire regione di nascita"
                                        placeholder={'Regione'}
                                        options={regionOptions}
                                        onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setBirthRegion(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Provincia di nascita</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Provincia'}
                                        value={anagraficaState.createAnagraphicRequest.birthProvince}
                                        defaultValue={anagraficaState.createAnagraphicRequest.birthProvince}
                                        error={anagraficaState.anagraphicErrors.birthProvince}
                                        errorLabel="Inserire provincia di nascita"
                                        options={provinceOptions}
                                        onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setBirthProvince(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Comune di nascita</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Comune'}
                                        options={comuneOptions}
                                        value={anagraficaState.createAnagraphicRequest.birthCity}
                                        defaultValue={anagraficaState.createAnagraphicRequest.birthCity}
                                        error={anagraficaState.anagraphicErrors.birthCity}
                                        errorLabel="Inserire comune di nascita"
                                        onChange={(value) => {
                                            dispatch(setBirthCity(value))
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="text-left pt-5">
                                <span className="input-label" style={{ color: 'red' }}>Nel caso in cui l'utente è nato in uno stato straniero</span>
                            </div>

                            <div className="formAnagrafica pt-2" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Nazione di nascita</span>
                                    </div>
                                    <SelectCustom
                                        defaultValue={anagraficaState.createAnagraphicRequest.externalNation}
                                        value={anagraficaState.createAnagraphicRequest.externalNation}
                                        placeholder={'Nazione'}
                                        options={countries}
                                        onChange={(value) => { dispatch(setExternalNation(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>

                                    <Input
                                        label={'Provincia'}
                                        placeholder="Provincia"
                                        value={anagraficaState.createAnagraphicRequest.externalProvince}
                                        defaultValue={anagraficaState.createAnagraphicRequest.externalProvince}
                                        onChangeText={(text) => { dispatch(setExternalProvince(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>

                                    <Input
                                        label={'Comune'}
                                        placeholder="Comune"
                                        value={anagraficaState.createAnagraphicRequest.externalCity}
                                        defaultValue={anagraficaState.createAnagraphicRequest.externalCity}
                                        onChangeText={(text) => { dispatch(setExternalCity(text)) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel2' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel2-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Recapiti</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">
                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Regione</span>
                                    </div>
                                    <SelectCustom
                                        defaultValue={anagraficaState.createAnagraphicRequest.region}
                                        value={anagraficaState.createAnagraphicRequest.region}
                                        placeholder={'Regione'}
                                        options={regionOptions}
                                        onChange={(value) => { handleRpRegionChange(value as unknown as OptionType | null); dispatch(setRegion(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Provincia</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Provincia'}
                                        value={anagraficaState.createAnagraphicRequest.province}
                                        defaultValue={anagraficaState.createAnagraphicRequest.province}
                                        options={provinceOptions}
                                        onChange={(value) => { handleRpProvinceChange(value as unknown as OptionType | null); dispatch(setProvince(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Comune</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Comune'}
                                        options={comuneOptions}
                                        value={anagraficaState.createAnagraphicRequest.city}
                                        defaultValue={anagraficaState.createAnagraphicRequest.city}
                                        onChange={(value) => {
                                            dispatch(setCity(value))
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '1' }}>
                                    <Input
                                        label={'Indirizzo'}
                                        placeholder="Indirizzo"
                                        value={anagraficaState.createAnagraphicRequest.address}
                                        defaultValue={anagraficaState.createAnagraphicRequest.address}
                                        onChangeText={(text) => { dispatch(setAddress(text)) }}
                                    />
                                </div>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel3' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel3-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Altri recapiti</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">
                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Email'}
                                        placeholder="Email"
                                        value={anagraficaState.createAnagraphicRequest.firstEmail}
                                        defaultValue={anagraficaState.createAnagraphicRequest.firstEmail}
                                        error={anagraficaState.anagraphicErrors.firstEmail}
                                        supportingText={anagraficaState.anagraphicErrors.surname ? "Inserire email" : undefined}
                                        onChangeText={(text) => { dispatch(setFirstEmail(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Email 2'}
                                        placeholder='Email 2'
                                        value={anagraficaState.createAnagraphicRequest.secondEmail}
                                        defaultValue={anagraficaState.createAnagraphicRequest.secondEmail}
                                        onChangeText={(text) => { dispatch(setSecondEmail(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Pec'}
                                        placeholder="Pec"
                                        value={anagraficaState.createAnagraphicRequest.pec}
                                        defaultValue={anagraficaState.createAnagraphicRequest.pec}
                                        error={anagraficaState.anagraphicErrors.pec}
                                        supportingText={anagraficaState.anagraphicErrors.pec ? "Inserire pec" : undefined}
                                        onChangeText={(text) => { dispatch(setPec(text)) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordion('panel4')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel4' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel4-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Rubrica</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">

                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Telefono Abitazione 1'}
                                        placeholder="Telefono Abitazione 1"
                                        value={anagraficaState.createAnagraphicRequest.firstHouse}
                                        defaultValue={anagraficaState.createAnagraphicRequest.firstHouse}
                                        onChangeText={(text) => { dispatch(setFirstHouse(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Telefono Abitazione 2'}
                                        placeholder='Telefono Abitazione 2'
                                        value={anagraficaState.createAnagraphicRequest.secondHouse}
                                        defaultValue={anagraficaState.createAnagraphicRequest.secondHouse}
                                        onChangeText={(text) => { dispatch(setSecondHouse(text)) }}
                                    />
                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Telefono Ufficio 1'}
                                        placeholder="Telefono Ufficio 2"
                                        value={anagraficaState.createAnagraphicRequest.firstOffice}
                                        defaultValue={anagraficaState.createAnagraphicRequest.firstOffice}
                                        onChangeText={(text) => { dispatch(setFirstOffice(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Telefono Ufficio 2'}
                                        placeholder='Telefono Ufficio 2'
                                        value={anagraficaState.createAnagraphicRequest.secondOffice}
                                        defaultValue={anagraficaState.createAnagraphicRequest.secondOffice}
                                        onChangeText={(text) => { dispatch(setSecondOffice(text)) }}
                                    />
                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Fax 1'}
                                        placeholder="Fax 1"
                                        value={anagraficaState.createAnagraphicRequest.firstFax}
                                        defaultValue={anagraficaState.createAnagraphicRequest.firstFax}
                                        onChangeText={(text) => { dispatch(setFirstFax(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Fax 2'}
                                        placeholder='Fax 2'
                                        value={anagraficaState.createAnagraphicRequest.secondFax}
                                        defaultValue={anagraficaState.createAnagraphicRequest.secondFax}
                                        onChangeText={(text) => { dispatch(setSecondFax(text)) }}
                                    />
                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Cellulare 1'}
                                        placeholder="Cellulare 1"
                                        value={anagraficaState.createAnagraphicRequest.firstPhone}
                                        defaultValue={anagraficaState.createAnagraphicRequest.firstPhone}
                                        error={anagraficaState.anagraphicErrors.firstPhone}
                                        supportingText={anagraficaState.anagraphicErrors.firstPhone ? "Inserire telefono" : undefined}
                                        onChangeText={(text) => { dispatch(setFirstPhone(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Cellulare 2'}
                                        placeholder='Cellulare 2'
                                        value={anagraficaState.createAnagraphicRequest.secondPhone}
                                        defaultValue={anagraficaState.createAnagraphicRequest.secondPhone}
                                        onChangeText={(text) => { dispatch(setSecondPhone(text)) }}
                                    />
                                </div>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordion('panel5')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel5' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel5-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Dati fiscali</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">

                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '1' }}>
                                    <Input
                                        label={'Codice fiscale'}
                                        placeholder="Codice fiscale"
                                        value={anagraficaState.createAnagraphicRequest.fiscalCode}
                                        defaultValue={anagraficaState.createAnagraphicRequest.fiscalCode}
                                        error={anagraficaState.anagraphicErrors.fiscalCode}
                                        supportingText={anagraficaState.anagraphicErrors.fiscalCode ? "Inserire cod. fiscale" : undefined}
                                        onChangeText={(text) => { dispatch(setFiscalCode(text)) }}
                                    />
                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Partita Iva'}
                                        placeholder="Partita Iva"
                                        defaultValue={anagraficaState.createAnagraphicRequest.vat}
                                        onChangeText={(text) => { dispatch(setVat(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Codice destinatario (SDI)'}
                                        placeholder='Codice destinatario (SDI)'
                                        value={anagraficaState.createAnagraphicRequest.sdi}
                                        defaultValue={anagraficaState.createAnagraphicRequest.sdi}
                                        onChangeText={(text) => { dispatch(setSdi(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.33' }}>
                                    <div className="text-left">
                                        <span className="input-label">Regime fiscale</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Regime fiscale'}
                                        options={fiscalRegimeOptions}
                                        value={String(anagraficaState.createAnagraphicRequest.fiscalRegime)}
                                        defaultValue={String(anagraficaState.createAnagraphicRequest.fiscalRegime)}
                                        error={anagraficaState.anagraphicErrors.fiscalRegime}
                                        errorLabel="Inserire regime fiscale"
                                        onChange={(value) => {
                                            dispatch(setFiscalRegime(value))
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChangeAccordion('panel6')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel6' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel6-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Altri dati</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">

                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Data ingresso</span>
                                    </div>
                                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                                        selected={anagraficaState.createAnagraphicRequest.starting}
                                        customInput={<Input supportingText={anagraficaState.anagraphicErrors.starting ? "Inserire data inizio." : undefined} error={anagraficaState.anagraphicErrors.starting} startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                        onChange={
                                            (date) => {
                                                if (date !== null) {
                                                    dispatch(setStarting(format(new Date(date), 'yyyy-MM-dd')))
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Data uscita</span>
                                    </div>
                                    <DatePicker locale="it" dateFormat="d/MM/yyyy"
                                        selected={anagraficaState.createAnagraphicRequest.ending}
                                        customInput={<Input startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                                        onChange={
                                            (date) => {
                                                if (date !== null) {
                                                    dispatch(setEnding(format(new Date(date), 'yyyy-MM-dd')))
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '1' }}>
                                    <div className="text-left">
                                        <span className="input-label">Note</span>
                                    </div>
                                    <TextArea placeholder="Testo segnaposto nota aggiuntiva" onChangeText={(text) => dispatch(setNote(text))} />
                                </div>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel7'} onChange={handleChangeAccordion('panel7')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel7' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel7-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Iscrizione al collegio o ordine professionale</span></div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">

                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Albo professionale'}
                                        placeholder="Albo professionale"
                                        value={anagraficaState.createAnagraphicRequest.professionalRegister}
                                        defaultValue={anagraficaState.createAnagraphicRequest.professionalRegister}
                                        onChangeText={(text) => { dispatch(setProfessionalRegister(text)) }}
                                    />
                                </div>

                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'Comune'}
                                        placeholder='Comune'
                                        value={anagraficaState.createAnagraphicRequest.cityRegister}
                                        defaultValue={anagraficaState.createAnagraphicRequest.cityRegister}
                                        onChangeText={(text) => { dispatch(setCityRegister(text)) }}
                                    />
                                </div>

                                <div style={{ flex: '0.33' }}>
                                    <Input
                                        label={'N° di registro'}
                                        placeholder='N° di registro'
                                        value={anagraficaState.createAnagraphicRequest.numRegister}
                                        defaultValue={anagraficaState.createAnagraphicRequest.numRegister}
                                        onChangeText={(text) => { dispatch(setNumRegister(text)) }}
                                    />
                                </div>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}