import { ReactElement, ReactNode, useEffect, useState } from "react";
import { TrashIcon } from "../../ui/icons/trash";
import Button from "../../ui/molecules/button";
import { SelectCustom } from "../../ui/molecules/select";
import { attivita_bio, categorie_prodotti } from "../../utils";
import { log } from "console";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { setActivities, setProductCategories } from "../clienti/slice";
import { commonRightBarStyle } from "./style";

interface CustomSelectProps {
  options: { label: string, value: string }[];
  idx: number;
  onDelete: (idx: number) => void;
}


export function Bio() {
  const clientiState = useAppSelector(state => state.clienti)
  const dispatch = useAppDispatch()
  let activities: ReactNode[] = []
  let categories: ReactNode[] = []

  useEffect(() => {
    if (clientiState.getCustomersByIdResponse !== undefined) {
      dispatch(setActivities(clientiState.getCustomersByIdResponse.bioActivity));
      dispatch(setProductCategories(clientiState.getCustomersByIdResponse.bioProductCategories))
      
  
    }
  }, [clientiState.getCustomersByIdResponseStatus]);

  if (clientiState.clienteEditCreateRequest.bioActivity !== undefined && clientiState.clienteEditCreateRequest.bioActivity?.length > 0) {
    clientiState.clienteEditCreateRequest.bioActivity.forEach((element, index) => {
      activities.push(
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 0.95, paddingRight: 5 }}>
            <SelectCustom
              placeholder="Attività"
              options={attivita_bio}
              defaultValue={element}
              value={element}
              onChange={(value) => {
                const newRegister = [...clientiState.clienteEditCreateRequest.bioActivity]
                if (value !== undefined) {
                  newRegister[index] = value
                  dispatch(setActivities(newRegister))
                }
              }}

            />
          </div>
          <div style={{ flex: 0.05 }} onClick={() => {

            let newActivities = [...clientiState.clienteEditCreateRequest.bioActivity]
            newActivities = newActivities.slice(0, index).concat(newActivities.slice(index + 1));
            dispatch(setActivities(newActivities.length === 0 ? [''] : newActivities))
          }
          }>
            <TrashIcon color="#8383AD" size={5} />
          </div>
        </div>
      )
    });
  }

  if (clientiState.clienteEditCreateRequest.bioProductCategories !== undefined && clientiState.clienteEditCreateRequest.bioProductCategories.length > 0) {
    clientiState.clienteEditCreateRequest.bioProductCategories.forEach((element, index) => {
      categories.push(
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 0.95, paddingRight: 5 }}>
            <SelectCustom
              placeholder="Categoria prodotti"
              options={categorie_prodotti}
              defaultValue={element}
              value={element}
              onChange={(value) => {
                const newCategory = [...clientiState.clienteEditCreateRequest.bioProductCategories]
                if (value !== undefined) {
                  newCategory[index] = value
                  dispatch(setProductCategories(newCategory))
                }
              }}

            />
          </div>
          <div style={{ flex: 0.05 }} onClick={() => {
            let newCategories = [...clientiState.clienteEditCreateRequest.bioProductCategories]
            newCategories = newCategories.slice(0, index).concat(newCategories.slice(index + 1));
            dispatch(setProductCategories(newCategories.length === 0 ? [''] : newCategories))
          }
          }>
            <TrashIcon color="#8383AD" size={5} />
          </div>
        </div>
      )
    })
  }


  return (
    <div>
      <div
        style={commonRightBarStyle}
      >
        <span className="input-label">Attività</span>
        {activities}

        <div className="mt-2">
          <Button
            onClick={() => {
              const newActivities = [...clientiState.clienteEditCreateRequest.bioActivity]
              newActivities.push('')
              dispatch(setActivities(newActivities))
            }}
            label="Aggiungi attività"
            size="sm"
            iconPosition="off"
            variant="outline"
            color="dimgrey"
          />
        </div>

      </div>
      <div
        className="borderTopSection mt-6"
        style={{...commonRightBarStyle,
          paddingTop: 15
        }}
      >
        <span className="input-label">Categoria prodotti</span>
        {categories}

        <div className="mt-2">
          <Button
            onClick={() => {
              const newCategories = [...clientiState.clienteEditCreateRequest.bioProductCategories]
              newCategories.push('')
              dispatch(setProductCategories(newCategories))
            }}
            label="Aggiungi categoria"
            size="sm"
            iconPosition="off"
            variant="outline"
            color="dimgrey"
          />
        </div>

      </div>
    </div>
  );
}
