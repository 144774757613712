import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { EditUserRequestDTO, FindAllUsersDTO, FindAllUsersFilters, UserDTO, UserEditCreationDTO } from "./dto"
import { NewUsersService } from "./service"
import { PromiseStatuses } from "../../lib/utils"
import { format } from "date-fns";
import { addTechinicianUserDTO } from "../businessTechnician/dto";

interface UsersState {
    createUserErrors: {
        role: boolean,
        text: string,
        name: boolean,
        surname: boolean,
        fiscalCode: boolean,
        email: boolean,
        sot: boolean,

    },
    userCreationStatus: PromiseStatuses
    getUsersResponse?: FindAllUsersDTO
    getUsersStatus: PromiseStatuses
    deleteUserStatus: PromiseStatuses
    usersEditCreateRequest: UserEditCreationDTO
    filters: FindAllUsersFilters
    selectedUserId: string
    findUserByIdStatus: PromiseStatuses
    findUserByIdResponse?: UserDTO
    editUserRequest: UserEditCreationDTO
    editUserStatus: PromiseStatuses
}

const initialState: UsersState = {
    createUserErrors: {
        role: false,
        text: '',
        name: false,
        surname: false,
        fiscalCode: false,
        email: false,
        sot: false,

    },
    userCreationStatus: 'idle',
    getUsersStatus: 'idle',
    editUserStatus: 'idle',
    findUserByIdStatus: 'idle',
    deleteUserStatus: 'idle',
    selectedUserId: '',
    filters: {
        headquarter: undefined,
        active: undefined,
        order: true,
        sort: undefined,
        itemsPerPage: 10,
        page: 0
    },
    usersEditCreateRequest: {
        username: '',
        name: '',
        surname: '',
        email: '',
        role: '',
        fiscalCode: '',
        headQuarter: '',
        activationDate: new Date(),
        pec: '',
        active: true,
        phone: '',
        office: 'Amm'
    },
    editUserRequest: {
        username: '',
        name: '',
        surname: '',
        email: '',
        role: '',
        fiscalCode: '',
        headQuarter: '',
        activationDate: new Date(),
        deactivationDate: new Date(),
        pec: '',
        active: true,
        phone: '',
        office: 'Amm'
    }

}


export const UserCreation = createAsyncThunk(
    'users/UserCreation',
    async (request: UserEditCreationDTO, thunkApi): Promise<void> => {
        const usersService = NewUsersService()

        return usersService.createUser(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async (filters: FindAllUsersFilters, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()
        return UsersService.getUsers(filters)
    }
)

export const findUserById = createAsyncThunk(
    'users/findUserById',
    async (id: string, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.findUserById(id)
    }
)

export const editUser = createAsyncThunk(
    'users/editUser',
    async (request: { id: string, data: UserEditCreationDTO }, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.editUser(request.id, request.data)
    }
)

export const RemoveUser = createAsyncThunk(
    'users/RemoveUser',
    async (id: string, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.RemoveUser(id)
    }
)

export const linkBusinessTech = createAsyncThunk(
    'users/linkBusinessTech',
    async (data: addTechinicianUserDTO, thunkApi): Promise<number> => {
        console.log('richiesta slice ', data)
        const businessTechinicianService = NewUsersService()

        return businessTechinicianService.linkBusinessTech(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)


const UsersSlice = createSlice({
    name: 'users/slice',
    initialState,
    reducers: {
        setUsername: (state, action) => {
            state.usersEditCreateRequest.username = action.payload
        },
        setName: (state, action) => {
            state.usersEditCreateRequest.name = action.payload
        },
        setSurname: (state, action) => {
            state.usersEditCreateRequest.surname = action.payload
        },
        setRole: (state, action) => {
            state.usersEditCreateRequest.role = action.payload
        },
        setEmail: (state, action) => {
            state.usersEditCreateRequest.email = action.payload
        },
        setPhone: (state, action) => {
            state.usersEditCreateRequest.phone = action.payload
        },
        setPec: (state, action) => {
            state.usersEditCreateRequest.pec = action.payload
        },
        setFiscalCode: (state, action) => {
            state.usersEditCreateRequest.fiscalCode = action.payload
        },
        setHeadQuarter: (state, action) => {
            state.usersEditCreateRequest.headQuarter = action.payload
        },
        setHeadQuarterFilter: (state, action) => {
            state.filters.headquarter = action.payload
        },
        setStatusFilter: (state, action) => {
            if(action.payload === 'active') {
                state.filters.active= true
            } else {
                state.filters.active = false
            }
        },
        setActivationDate: (state, action) => {
            state.usersEditCreateRequest.activationDate = action.payload
        },
        setDeActivationDate: (state, action) => {
            state.usersEditCreateRequest.deactivationDate = action.payload
        },
        setFindAllUsersFiltersPage: (state, action) => {
            state.filters.page = action.payload
        },
        selectedUserId: (state, action) => {
            state.selectedUserId = action.payload
        },
        resetCreationStatusIdle: (state) => {
            state.userCreationStatus = 'idle'
        },
        resetEditUserStatusIdle: (state) => {
            state.editUserStatus = 'idle'
        },

        resetGetUserByIdStatusIdle: (state) => {
            state.findUserByIdStatus = 'idle'
        },


    },
    extraReducers(builder) {
        builder
            .addCase(UserCreation.pending, (state) => {
                state.userCreationStatus = 'loading'
            })
            .addCase(UserCreation.fulfilled, (state) => {
                state.userCreationStatus = 'successfully'
            })
            .addCase(UserCreation.rejected, (state, action) => {
                state.userCreationStatus = 'failed'
                //@ts-ignore
                if (action.payload.response.data.errors === 'Error creating user on keycloak') {
                    state.userCreationStatus = 'idle'
                    state.createUserErrors.text = 'Questa email è già stata utilizzata.'
                } else {
                    state.userCreationStatus = 'failed'
                }

            })

            .addCase(getUsers.pending, (state) => {
                state.getUsersStatus = 'loading'
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.getUsersStatus = 'successfully'
                state.getUsersResponse = action.payload
            })
            .addCase(getUsers.rejected, (state) => {
                state.getUsersStatus = 'failed'
            })

            .addCase(findUserById.pending, (state) => {
                state.findUserByIdStatus = 'loading'
            })
            .addCase(findUserById.fulfilled, (state, action) => {
                state.findUserByIdStatus = 'successfully'
                state.findUserByIdResponse = action.payload
            })
            .addCase(findUserById.rejected, (state) => {
                state.findUserByIdStatus = 'failed'
            })

            .addCase(editUser.pending, (state) => {
                state.editUserStatus = 'loading'
            })
            .addCase(editUser.fulfilled, (state, action) => {
                state.editUserStatus = 'successfully'
            })
            .addCase(editUser.rejected, (state) => {
                state.editUserStatus = 'failed'
            })

            .addCase(RemoveUser.pending, (state) => {
                state.editUserStatus = 'loading'
            })
            .addCase(RemoveUser.fulfilled, (state, action) => {
                state.editUserStatus = 'successfully'
            })
            .addCase(RemoveUser.rejected, (state) => {
                state.editUserStatus = 'failed'
            })

    },
})

export const {
    setUsername,
    setHeadQuarterFilter,
    setName,
    setEmail,
    setFiscalCode,
    setHeadQuarter,
    setRole,
    setPec,
    setSurname,
    setPhone,
    setActivationDate,
    setFindAllUsersFiltersPage,
    setStatusFilter,
    setDeActivationDate,
    selectedUserId,
    resetEditUserStatusIdle,
    resetCreationStatusIdle,
    resetGetUserByIdStatusIdle
} = UsersSlice.actions

export default UsersSlice.reducer