import { useEffect, useState } from "react";
import { attivita_bio, formatterDate, addonSelect } from "../../../utils";
import { CalendarIcon } from "../../icons/calendar";
import { CloseIcon } from "../../icons/close";
import { PopupForm } from "../../organisms/popupForm";
import { SelectCustom } from "../select";
import "./styles.scss";
import Input from "../input";
import DatePicker from "react-datepicker";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import {
  addAdOn,
  addCustomAddOn,
  GetAddOns,
  GetCustomersById,
  PostAddOn,
  removeAddOn,
  resetAddOnRequest,
  resetPostAddOnStatus,
  resetUpdateAddOnStatus,
  setAddOnRequestEndDate,
  setAddOnRequestModule,
  setAddOnRequestNotes,
  setAddOnRequestStartDate,
  setAddOns,
  setPostAddOnRequestActive,
  setPostAddOnRequestCustomerDataId,
  setPostAddOnRequestEnding,
  setPostAddOnRequestModule,
  setPostAddOnRequestNotes,
  setPostAddOnRequestStarting,
  setUpdateAddOnRequestActive,
  setUpdateAddOnRequestCustomerDataId,
  setUpdateAddOnRequestEnding,
  setUpdateAddOnRequestModule,
  setUpdateAddOnRequestNotes,
  setUpdateAddOnRequestStarting,
  UpdateAddOn,
} from "../../../pages/clienti/slice";
import { format } from "date-fns";
import Button from "../button";

interface Props {
  closeHandler: () => void;
  onEdit?: boolean;
  idx?: number;
  id?: number;
  visible?: boolean;
}

export function AddOnPopup(props: Props) {
  const { closeHandler, onEdit, id, visible, idx } = props;

  const dispatch = useAppDispatch();
  const clientiState = useAppSelector(state => state.clienti);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [module, setModule] = useState<string | undefined>('');
  const [notes, setNotes] = useState<string | undefined>('')

  const onEditHandler = () => {
    if(!clientiState.onEditCustomer) {
      if(clientiState.addOnRequest.addModule === '')
        return;
      if(onEdit && idx !== undefined) {
        dispatch(removeAddOn(idx));
      }
      dispatch(addAdOn());
      dispatch(resetAddOnRequest());
      closeHandler();
    } else {
      if(onEdit && id !== undefined) {
        dispatch(UpdateAddOn({
          id: String(id),
          data: clientiState.updateAddOnRequest
        }));
      } else if(onEdit === undefined){
        dispatch(PostAddOn(clientiState.postAddOnRequest))
      }
    }
  }

  useEffect(() => {
    if(idx !== undefined) {
      setStartDate(clientiState.clienteEditCreateRequest.ggAddOns[idx].starting)
      setEndDate(clientiState.clienteEditCreateRequest.ggAddOns[idx].ending)
      setModule(clientiState.clienteEditCreateRequest.ggAddOns[idx].addModule)
      setNotes(clientiState.clienteEditCreateRequest.ggAddOns[idx].addOn)
    }
    if(!clientiState.onEditCustomer) {
      if(onEdit && idx !== undefined) {
        dispatch(setAddOnRequestModule(clientiState.clienteEditCreateRequest.ggAddOns[idx].addModule));
        dispatch(setAddOnRequestStartDate(clientiState.clienteEditCreateRequest.ggAddOns[idx].starting));
        dispatch(setAddOnRequestEndDate(clientiState.clienteEditCreateRequest.ggAddOns[idx].ending));
        dispatch(setAddOnRequestNotes(clientiState.clienteEditCreateRequest.ggAddOns[idx].addOn))
      }
    } else {
      if(onEdit && idx !== undefined) {
        dispatch(setUpdateAddOnRequestActive(true));
        dispatch(setUpdateAddOnRequestCustomerDataId(clientiState.selectedCustomerId))
        dispatch(setUpdateAddOnRequestEnding(clientiState.clienteEditCreateRequest.ggAddOns[idx].ending))
        dispatch(setUpdateAddOnRequestModule(clientiState.clienteEditCreateRequest.ggAddOns[idx].addModule))
        dispatch(setUpdateAddOnRequestStarting(clientiState.clienteEditCreateRequest.ggAddOns[idx].starting))
        dispatch(setUpdateAddOnRequestNotes(clientiState.clienteEditCreateRequest.ggAddOns[idx].addOn))
      } 
      dispatch(setPostAddOnRequestActive(true));
      dispatch(setPostAddOnRequestCustomerDataId(clientiState.selectedCustomerId));
    }
  }, [visible, dispatch])

  useEffect(() => {
    if(clientiState.updateAddOnStatus === 'successfully') {
      dispatch(resetUpdateAddOnStatus());
      dispatch(resetAddOnRequest());
      dispatch(setAddOns([]));
      dispatch(GetAddOns());
      closeHandler();
    }
  }, [clientiState.updateAddOnStatus])

  useEffect(() => {
    if(clientiState.postAddOnStatus === 'successfully' && clientiState.postAddOnResponse !== undefined) {
      dispatch(resetPostAddOnStatus());
      dispatch(resetAddOnRequest());
      dispatch(setAddOns([]));
      dispatch(GetAddOns());
      closeHandler();
    }
  }, [clientiState.postAddOnStatus])

  const onModuleChange = (value: string | undefined) => {
    setModule(value)
    if(clientiState.onEditCustomer && onEdit && id !== undefined)
      dispatch(setUpdateAddOnRequestModule(value))
    else if(!clientiState.onEditCustomer) 
      dispatch(setAddOnRequestModule(value))
    else if(clientiState.onEditCustomer && onEdit === undefined)
      dispatch(setPostAddOnRequestModule(value))
  }

  const onStartDate = (date: Date | null) => {
    if(date !== null) {
      if(!clientiState.onEditCustomer) {
        dispatch(
          setAddOnRequestStartDate(
            format(new Date(date), "yyyy-MM-dd")
          )
        );
      } else {
        if(onEdit) {
          dispatch(
            setUpdateAddOnRequestStarting(
              format(new Date(date), "yyyy-MM-dd"))
          )
        } else {
          dispatch(
            setPostAddOnRequestStarting(
              format(new Date(date), "yyyy-MM-dd"))
          )
        }
      }
      setStartDate(date);
    }
  }

  const onEndDate = (date: Date | null) => {
    if(date !== null) {
      if(!clientiState.onEditCustomer) {
        dispatch(
          setAddOnRequestEndDate(
            format(new Date(date), "yyyy-MM-dd")
          )
        );
      } else {
        if(onEdit) {
          dispatch(
            setUpdateAddOnRequestEnding(
              format(new Date(date), "yyyy-MM-dd"))
          )
        } else {
          dispatch(
            setPostAddOnRequestEnding(
              format(new Date(date), "yyyy-MM-dd"))
          )
        }
      }
      setEndDate(date);
    }
  }

  const onNotesChange = (value: string | undefined) => {
    setNotes(value)
    if(clientiState.onEditCustomer && onEdit && id !== undefined)
      dispatch(setUpdateAddOnRequestNotes(value))
    else if(!clientiState.onEditCustomer) 
      dispatch(setAddOnRequestNotes(value))
    else if(clientiState.onEditCustomer && onEdit === undefined)
      dispatch(setPostAddOnRequestNotes(value))
  }

  return (
    <>
      <div className="darkBG" onClick={() => closeHandler()} />

      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            <h5 className="heading">Aggiungi add-on</h5>
            <button className="closeBtn" onClick={() => {
              dispatch(resetAddOnRequest());
              closeHandler();
              }}>
              <CloseIcon />
            </button>
          </div>

          <div className="modalContainer">
            <div className="col pt-5">
              <span className="subtitle">Modulo</span>
              <SelectCustom
                defaultValue={module}
                value={module}
                onChange={(value) => onModuleChange(value)}
                options={addonSelect}
                placeholder="Modulo"
              />
            </div>

            <div className="col" style={{ marginTop: 20 }}>
              <span className="subtitle">Seleziona date</span>
              <div className="row">
                <div style={{ width: "216px" }}>
                  <DatePicker
                    locale="it"
                    selected={startDate}
                    customInput={
                      <Input
                        placeholder="Dal"
                        startIcon={<CalendarIcon size={20} color={"blue"} />}
                      />
                    }
                    dateFormat="d/MM/yyyy"
                    onChange={(date) => onStartDate(date)}
                  />
                </div>
                <div style={{ width: "216px" }}>
                  <DatePicker
                    locale="it"
                    selected={endDate}
                    customInput={
                      <Input
                        placeholder="Al"
                        startIcon={<CalendarIcon size={20} color={"blue"} />}
                      />
                    }
                    dateFormat="d/MM/yyyy"
                    onChange={(date) => onEndDate(date)}
                  />
                </div>
              </div>
            </div>

            <div className="col" style={{ marginTop: 20 }}>
              <span className="subtitle">Note</span>
              <div style={{ width: "100%" }}>
                <Input
                  defaultValue={notes}
                  value={notes}
                  onChangeText={(value) =>
                    onNotesChange(value)
                  }
                  placeholder="Note"
                />
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: 20, justifyContent: "flex-end" }}
            >
              <div style={{marginRight: 5}}>
                <Button
                  onClick={() =>{
                    dispatch(resetAddOnRequest());
                    closeHandler();
                  }}
                  label="Annulla"
                  size="sm"
                  variant="outline"
                  iconPosition="off"
                  color="lightGreen"
                />
              </div>
              <Button
                onClick={() => onEditHandler()}
                label="Aggiorna"
                size="sm"
                variant="solid"
                iconPosition="off"
                color="orange"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
