import React, { useEffect, useState } from "react";
import { TrashIcon } from "../../icons/trash";
import { CalendarIcon } from "../../icons/calendar";
import { addonSelect, formatterDate } from "../../../utils";
import { EditIcon } from "../../icons/edit";
import { AddOnPopup } from "../addOnPopup";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { DeleteAddOn, GetAddOns, GetCustomersById, resetDeleteAddOnStatus, setAddOns } from "../../../pages/clienti/slice";

interface Props {
  title: string;
  onDelete: () => void;
  startDate: Date;
  endDate: Date | undefined;
  note?: string;
  id?: number;
  idx?: number;
  onEdit?: boolean;
  popUpVisible?: boolean;
}

export function AddOnComponent(props: Props) {
  const { title, onDelete, startDate, endDate, note, id, onEdit, popUpVisible, idx } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const clientiState = useAppSelector(state => state.clienti);
  const dispatch = useAppDispatch();

  const onDeleteHandler = () => {
    if(clientiState.onEditCustomer) {
      dispatch(DeleteAddOn(String(id)));
    } else
    onDelete();
  }

  return (
    <div style={containerStyle}>
      <div style={row}>
        <span style={titleStyle} className="text-left">
          {
            addonSelect.filter(({ value }) => value === title).map(el =>
              <span key={el.value} className="text-left"> {el.label}</span>
            )
          }
        </span>
        <div>
          <button style={{paddingRight: 10}} onClick={() => setShowModal(true)} >
            <EditIcon color="#4d797f" size={14} />
          </button>
          <button onClick={() => onDeleteHandler()} >
            <TrashIcon color="#FF443D" size={14} />
          </button>
        </div>
      </div>

      <div style={row}>
        <div style={{ ...InputStyle, flex: 0.5, marginRight: "10px" }}>
          <div style={{ flex: 0.05 }}>
            <CalendarIcon size={13} color="white" />
          </div>
          <div style={{ flex: 0.95, textAlign: "left" }}>
            <span style={{ ...titleStyle, paddingRight: "3px" }}>Dal</span>
            <span style={{ ...titleStyle, fontWeight: 500 }}>{formatterDate(startDate)}</span>
          </div>
        </div>
        <div style={{ ...InputStyle, flex: 0.5 }}>
          <div style={{ flex: 0.05 }}>
            <CalendarIcon size={13} color="white" />
          </div>
          <div style={{ flex: 0.95, textAlign: "left" }}>
            <span style={{ ...titleStyle, paddingRight: "3px" }}>Al</span>
            <span style={{ ...titleStyle, fontWeight: 500 }}>{formatterDate(endDate)}</span>
          </div>
        </div>
      </div>

      <div style={row}>
        <div style={{ ...InputStyle, flex: 1, textAlign: 'left', justifyContent: 'flex-start' }}>
          <span style={NoteStyle}>{note && note || 'Note...'}</span>
        </div>
      </div>
      {
        showModal && <AddOnPopup idx={idx} visible={popUpVisible} id={id} onEdit={onEdit} closeHandler={() => setShowModal(false)} />
      }
    </div>
  );
}

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderWidth: "1px",
  borderColor: "#C0D5D8",
  borderRadius: "12px",
  padding: "16px 24px 16px 24px",
  gap: "12px",
  backgroundColor: "#D9E6E8",
};

const row: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const titleStyle: React.CSSProperties = {
  fontSize: "14px",
  color: "#314D51",
  fontFamily: "Manrope",
  fontWeight: 700,
  lineHeight: "16px",
};

const InputStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: "1px",
  borderColor: "#D9E6E8",
  borderRadius: "8px",
  backgroundColor: "white",
  height: "40px",
  padding: "0px 12px 0px 12px",
  gap: "8px",
};

const NoteStyle: React.CSSProperties = {
  fontSize: "14px",
  color: "#5D9298",
  fontFamily: "Manrope",
  fontWeight: 400,
  lineHeight: "16px",
};
