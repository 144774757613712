import clsx from "clsx"
import { ReactElement, ReactNode } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"


interface Props {
    children: ReactElement
    content?: ReactElement
    rightBar?: boolean
}

export function RightBar(props: Props) {
    const { children } = props

    const pathname = window.location.pathname

    const dispatch = useAppDispatch()

    return (
        <div>

            {children}

        </div>

    )
}