import { useEffect } from 'react';
import './App.css';
import { keycloak } from './app/lib/keycloak';
import { setKeycloakInitStatus, setRoles} from './app/lib/keycloak/slice';
import { useAppDispatch, useAppSelector } from './app/lib/redux/hooks';
import { KeycloakRouter } from './app/lib/router';
import { SpinnerComponent } from './app/lib/spinner';

function App() {
  const dispatch = useAppDispatch()


  const keycloakState = useAppSelector(state => state.keycloak)
  keycloak.onAuthSuccess = () => {
    
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles))
    
    dispatch(setKeycloakInitStatus('successfully'))
    
  }

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
  }


  if (
    keycloakState.keycloakInitStatus === 'idle'
  ) {
    return (<SpinnerComponent fullScreen size={'small'} />)
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;
