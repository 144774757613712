import { CSSProperties, ReactElement } from "react";

export interface ButtonProps {
    size: ButtonSize;
    label?: string;
    disabled?: boolean;
    icon?: ReactElement
    iconPosition: ButtonIconPosition
    variant: ButtonVariant
    color: ButtonColor
    onClick?: () => void
}

export type ButtonSize = 'sm' | 'md' | 'lg'
export type ButtonIconPosition = 'off' | 'only' | 'right' | 'left'
export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link'
export type ButtonColor = 'blue' | 'gray' | 'green' | 'white' | 'orange' | 'dimgrey' | 'lightGreen'

export const buttonColorMap = new Map<ButtonColor, { color: string, darker: string }>([
    ['blue', { color: 'rgba(59, 122, 247, 1)', darker: 'rgba(26, 37, 255, 1)' }],
    ['gray', { color: 'rgba(131, 131, 173, 1)', darker: 'rgba(98, 98, 138, 1)' }],
    ['green', { color: 'rgba(65, 204, 148, 1)', darker: 'rgba(48, 163, 117, 1)' }],
    ['white', { color: 'rgba(250, 252, 255, 1)', darker: 'rgba(250, 252, 255, 1)' }],
    ['orange', { color: 'rgba(255, 148, 26, 1)', darker: 'rgba(255, 148, 26, 1)' }],
    ['dimgrey', {color: 'rgba(77,121,127,1)', darker: 'rgba(77,121,127,1)'}],
    ['lightGreen', {color: 'rgba(93, 146, 152, 1)', darker: 'rgba(93,146,152,1)'}]
])

export const buttonSizeMap = new Map<ButtonSize, CSSProperties>([
    ['sm', { height: '32px', minWidth: '32px' }],
    ['md', { height: '40px', minWidth: '40px' }],
    ['lg', { height: '44px', minWidth: '44px' }],
])

export const buttonLabelSizeMap = new Map<ButtonSize, CSSProperties>([
    ['sm', {
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 600,
        height: '14px',
        lineHeight: '14px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }],
    ['md', {
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '14px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }],
    ['lg', {
        fontFamily: 'Manrope',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }],
])

export const buttonIconPositionMap = new Map<ButtonIconPosition, CSSProperties>([
    ['off', { padding: '0px 16px 0px 16px', }],
    ['only', { padding: '0px', }],
    ['left', { padding: '0px 16px 0px 16px', flexDirection: 'row' }],
    ['right', { padding: '0px 16px 0px 16px', flexDirection: 'row-reverse' }],
])


export const buttonVariantKey = (variant: string, darker: boolean): string => {
    return `${variant}-${darker}`;
};

export const colorKey = (color: ButtonColor, variantKey: string): { buttonIconVariant: string, buttonVariant: CSSProperties } => {
    const COLOR = buttonColorMap.get(color)?.color || ''
    const DARKERCOLOR = buttonColorMap.get(color)?.darker || ''

    const buttonIconVariantMap = new Map<string, string>([
        [buttonVariantKey('solid', true), 'white'],
        [buttonVariantKey('solid', false), 'white'],
        [buttonVariantKey('outline', true), DARKERCOLOR],
        [buttonVariantKey('outline', false), COLOR],
        [buttonVariantKey('ghost', true), DARKERCOLOR],
        [buttonVariantKey('ghost', false), COLOR],
        [buttonVariantKey('link', true), DARKERCOLOR],
        [buttonVariantKey('link', false), COLOR],
    ])

    const buttonVariantMap = new Map<string, CSSProperties>([
        [buttonVariantKey('solid', true), {
            backgroundColor: DARKERCOLOR,
            borderColor: DARKERCOLOR,
        }],
        [buttonVariantKey('solid', false), {
            backgroundColor: COLOR,
            borderColor: COLOR,
        }],
        [buttonVariantKey('outline', true), {
            backgroundColor: 'white',
            borderColor: DARKERCOLOR,
        }],
        [buttonVariantKey('outline', false), {
            backgroundColor: '#F5FDFF',
            borderColor: COLOR,
        }],
        [buttonVariantKey('ghost', true), {
            backgroundColor: 'white',
            borderColor: 'transparent',
        }],
        [buttonVariantKey('ghost', false), {
            backgroundColor: 'white',
            borderColor: 'transparent',
        }],
        [buttonVariantKey('link', true), {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }],
        [buttonVariantKey('link', false), {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }],
    ])

    return { buttonIconVariant: buttonIconVariantMap.get(variantKey) || '', buttonVariant: buttonVariantMap.get(variantKey) || {} }
};