import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import RadioButtonGroup from "../../molecules/RadioButtonGroup";
import { useEffect, useState } from "react";
import './style.scss';
import { SelectCustom } from "../../molecules/select";
import { normativa } from "../../../utils";
import { GetCustomers, setClientType, setLeaderId, setOnSettingClientTypology, setOperatorGroup } from "../../../pages/clienti/slice";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";


registerLocale('it', it)

enum CustomOptionsTypeCustomer {
    SINGLE = 'SINGOLO',
    GROUP = 'GRUPPO_DI_OPERATORI',
}

enum CustomOptionsTypeMemberCustomer {
    LEADER = 'CAPOFILA',
    MEMBER = 'MEMBRO',
}


export function ClientTypology() {
    const [capoFilaDisabled, setCapoFilaDisabled] = useState<boolean>(true)
    const [groupTypeDisabled, setGroupTypeDisabled] = useState<boolean>(true)
    const [selectedTipologiaOption, setSelectedTipologiaOption] = useState<CustomOptionsTypeCustomer | null>(null);
    const [selectedTipologiaGroupOption, setSelectedTipologiaGroupOption] = useState<CustomOptionsTypeMemberCustomer | null>(null);
    const [groupLeadersOptions, setGroupLeadersOptions] = useState<{value: string, label: string}[]>([])

    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setOnSettingClientTypology(true))
        dispatch(GetCustomers({operatorsGroup: CustomOptionsTypeMemberCustomer.LEADER}))
    }, [selectedTipologiaGroupOption])

    useEffect(() => {
        if(clientiState.getCustomersResponseStatus && 
            clientiState.getCustomersResponse !== undefined &&
            clientiState.onSettingClientTypology &&
            !clientiState.onEditCustomer
        ) {
            dispatch(setOnSettingClientTypology(false))
            for(const customer of clientiState.getCustomersResponse.data) {
                console.log(customer.id);
                
                if(groupLeadersOptions.filter(o => o.value === customer.id).length > 0)
                    return;

                const newOption = {
                    value: customer.id, 
                    label: `${customer.id} - ${customer.companyName}`
                };
                
                if(customer.operatorsGroup === CustomOptionsTypeMemberCustomer.LEADER) {
                    const currentLeaderGroup = groupLeadersOptions
                    currentLeaderGroup.push(newOption)
                    setGroupLeadersOptions([...currentLeaderGroup])
                }
            }
        }
    }, [clientiState.getCustomersResponseStatus])



    useEffect(() => {
        if (clientiState.getCustomersByIdResponse !== undefined) {
            const clientType = clientiState.getCustomersByIdResponse.clientType as CustomOptionsTypeCustomer;
            const operatorsGroup = clientiState.getCustomersByIdResponse.operatorsGroup as CustomOptionsTypeMemberCustomer;
            dispatch(setClientType(clientType));
            dispatch(setOperatorGroup(clientiState.getCustomersByIdResponse.operatorsGroup));
            setSelectedTipologiaOption(clientType);
            setSelectedTipologiaGroupOption(operatorsGroup)

        }
    }, [clientiState.getCustomersByIdResponseStatus]);

    const handleOptionTypeCustomerChange = (value: CustomOptionsTypeCustomer) => {
        setSelectedTipologiaOption(value);
        if (value === 'GRUPPO_DI_OPERATORI') {
            setClientType('GRUPPO_DI_OPERATORI')
            dispatch(setClientType('GRUPPO_DI_OPERATORI'))
            setGroupTypeDisabled(false)
        }
        else if (value === 'SINGOLO') {
            setClientType('SINGOLO')
            dispatch(setClientType('SINGOLO'))
            setSelectedTipologiaGroupOption(null);
            setGroupTypeDisabled(true)
            setCapoFilaDisabled(true)
        }

    };

    const handleOptionGroupTypeChange = (value: CustomOptionsTypeMemberCustomer) => {
        setSelectedTipologiaGroupOption(value);
        if (value === 'MEMBRO') {
            setCapoFilaDisabled(false)
            dispatch(setOperatorGroup('MEMBRO'))
        }
        else {
            setCapoFilaDisabled(true)
            dispatch(setOperatorGroup('CAPOFILA'))
        }

    };

    return (
        <div>
            <div className="borderTopSection mt-4">
                {
                    clientiState.createCustomerErrors.clientType && (
                        <div className="text-left mt-4" style={{ padding: '0px 24px' }}>
                            <span className="input-supporting-text">Scegliere tipologia cliente</span>
                        </div>

                    )
                }
                <div className="text-left mt-4" style={{ padding: '0px 24px' }}>
                    <span className="input-label">Tipologia Cliente</span>
                </div>
                <div className="mt-4" style={{ padding: '0px 24px' }}>

                    <RadioButtonGroup
                        options={Object.values(CustomOptionsTypeCustomer)}
                        selectedValue={selectedTipologiaOption}
                        onChange={handleOptionTypeCustomerChange}
                        name={'customer_type'}
                        optionLabels={{
                            [CustomOptionsTypeCustomer.SINGLE]: 'Singola',
                            [CustomOptionsTypeCustomer.GROUP]: 'Gruppo di Operatori',
                        }}
                    />
                </div>

                <div className="mt-4" style={{ padding: '0px 24px' }}>
                    <RadioButtonGroup
                        options={Object.values(CustomOptionsTypeMemberCustomer)}
                        selectedValue={selectedTipologiaGroupOption}
                        disabled={groupTypeDisabled}
                        name={'group_type'}
                        onChange={handleOptionGroupTypeChange}
                        optionLabels={{
                            [CustomOptionsTypeMemberCustomer.LEADER]: 'Capofila',
                            [CustomOptionsTypeMemberCustomer.MEMBER]: 'Membro',
                        }}
                    />
                    <div className="mt-6">
                        <SelectCustom
                            disabled={capoFilaDisabled}
                            placeholder={'Seleziona Capofila'}
                            options={groupLeadersOptions}
                            onChange={(value) => {
                                dispatch(setLeaderId(Number(value)))
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>

    )
}